import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Avatar, ButtonBase, Typography, type SxProps } from '@mui/material';
import type { BbsUser } from '@ocode/domain';
import { flatSx } from '@ocodelib/mui';
import clsx from 'clsx';
import { DEFAULT_PROFILE_IMAGE_URL } from '../config/code-config';
import { UserProfileDialogOpenEvent } from '../events/user-profile-open';

interface Props {
  sx?: SxProps;
  className?: string;

  /**
   * 사용자 정보
   */
  user: BbsUser;

  /**
   * 기본 액션 비활성 (프로필 팝업 비활성)
   */
  disableDefaultAction?: boolean;

  /**
   * 클릭 핸들러 - 클릭핸들러를 전달하지 않으면 프로필 팝업 표시
   */
  onClick?: React.MouseEventHandler;
}

export function BbsUserBox(props: Props) {
  const { sx, className, user, disableDefaultAction = false, onClick } = props;
  const { accountId, nickName, profileImageUrl = DEFAULT_PROFILE_IMAGE_URL } = user;

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (disableDefaultAction || accountId === 0) return;
    UserProfileDialogOpenEvent.emit(accountId);
  };

  // 탈퇴한 사용자는 클릭 무시
  const clickHandler = accountId === 0 ? undefined : (onClick ?? handleClick);

  return (
    <ButtonBase
      component="div"
      disableRipple
      className={clsx('BbsUserBox-root', className, { x_button: !!onClick })}
      sx={flatSx(
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          py: 0.5,
          px: 1,
          cursor: 'pointer',
          '& svg': {
            color: '#666',
          },
          '& .BbsUserBox-name': {
            ml: 0.5,
            color: accountId === 0 ? '#888' : '#444',
            wordBreak: 'break-all',
          },
          '&.x_button:hover': {
            cursor: 'pointer',
            '& .BbsUserBox-name': {
              color: accountId === 0 ? '#777' : '#000',
            },
            '& svg': {
              color: '#222',
            },
          },
        },
        sx,
      )}
      onClick={clickHandler}
    >
      {accountId > 0 ? (
        <Avatar
          aria-label="user profile"
          src={profileImageUrl}
          sx={{
            fontSize: '1.0rem',
            width: 32,
            height: 32,
            border: '1px solid #eee',
          }}
        />
      ) : (
        // 탈퇴한 사용자
        <AccountCircleIcon />
      )}
      <Typography
        component="div"
        className="BbsUserBox-name"
        sx={{
          maxWidth: 80,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {nickName}
      </Typography>
    </ButtonBase>
  );
}
