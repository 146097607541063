import { SUBMENUS_ARRAY } from '../../main-layout-top-menus';
import { mainLayoutConstants as LAYOUT } from '../../main-layout-constants';

export const MENU_BAR_WIDTH = 688;
export const LOGO_WIDTH = 174;
const LOGO_HEIGHT = 20;
export const TOPBAR_HEIGHT = LAYOUT.appbarHeight;

type MatchFn = (path: string) => boolean;

export const isCurrentMenu = (
  menuHref: string,
  currentPath: string | undefined | null,
  matchFn?: MatchFn,
): boolean => {
  if (!currentPath) {
    return false;
  }

  // const currPath = removeLangPrefix(currentPath);
  const currPath = currentPath;
  if (currPath === menuHref) return true;

  // console.log('isCurrentMenu(): current=' + currPath);

  if (matchFn) {
    return matchFn(currPath);
  }
  return currPath.startsWith(`${menuHref}/`);
};

export const resolveMenuSectionIndex = (path: string | undefined | null): number => {
  if (!path) {
    return -1;
  }

  for (let menuIdx = 0; menuIdx < SUBMENUS_ARRAY.length; menuIdx++) {
    const submenus = SUBMENUS_ARRAY[menuIdx]!;
    for (let k = 0; k < submenus.length; k++) {
      const href = submenus[k]!.href;
      if (isCurrentMenu(href, path)) {
        return menuIdx;
      }
    }
  }
  return -1;
};

/**
 * 주어진 경로의 lang prefix를 제거한다.
 * 쿼리 문자열도 제거한다.
 *
 * @param str - 대상 문자열
 * @returns lang prefix가 제거된 문자열
 *
 * @example
 * removeLangPrefix('/') => '/'
 * removeLangPrefix('') => '/'
 * removeLangPrefix('/ko/sim') => '/sim'
 * removeLangPrefix('/ko/sim/') => '/sim'
 * removeLangPrefix('/ko/sim/hello') => '/sim/hello'
 */
function removeLangPrefix(str: string): string {
  if (str.length === 0) return '/';

  let s = str;
  const idx = s.indexOf('?');
  if (idx >= 0) {
    s = s.substring(0, idx);
  }

  const arr = s.split('/').filter((it) => it.length > 0);
  if (arr.length <= 0) {
    return str;
  }
  return '/' + arr.slice(1).join('/');
}
