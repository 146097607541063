import EastIcon from '@mui/icons-material/East';
import { Box, Button, Container, Grid2, Typography, useMediaQuery } from '@mui/material';
import { Center, FlexColumn } from '@ocodelib/mui';
import { LinkI18n } from '@repo-ui/i18n/routing';
import { CODING_CARD_DATA } from './coding-card-datas';
import CodingCard from './CodingCard';

export default function HomeCodingIntro() {
  const isVerySmall = useMediaQuery(`(max-width:600px)`);
  const isSmall = useMediaQuery(`(max-width:1000px)`);
  const columnCount = isVerySmall ? 1 : isSmall ? 2 : 4;

  const centerAlign = columnCount === 1;

  return (
    <Box
      className="HomeCodingIntro-root"
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        background: '#26282a',
        pt: 8,
        pb: 10,
      }}
    >
      <Container maxWidth="lg">
        <FlexColumn
          className="HomeCodingIntro-titleBox"
          alignItems={centerAlign ? 'center' : 'flex-start'}
          sx={{ width: '100%' }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: '2.4rem', md: '2.6rem' },
              fontWeight: 600,
              color: '#fff',
            }}
          >
            코딩하기
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              fontWeight: 300,
              fontSize: { xs: '1.1rem', lg: '1.2rem' },
              mt: 1.5,
            }}
          >
            기초 블록코딩에서 고급 텍스트코딩, 그래픽까지 다양한 프로그래밍 언어 구현
          </Typography>
        </FlexColumn>

        <Box
          sx={{
            mt: 5,
            display: 'block',
            ...(centerAlign && {
              display: 'flex',
              justifyContent: 'center',
            }),
          }}
        >
          <Grid2
            container
            columnSpacing={2.5}
            rowSpacing={3}
            columns={columnCount}
            sx={{
              mt: 1,
              maxWidth: columnCount === 1 ? 600 : '100%',
            }}
          >
            {CODING_CARD_DATA.map((it) => (
              <Grid2 key={it.title} size={{ xs: 1 }}>
                <CodingCard {...it} horizontal={columnCount === 1} />
              </Grid2>
            ))}
          </Grid2>
        </Box>

        {/* 자세히 보기 버튼 */}
        <Center>
          <Button
            color="inherit"
            variant="outlined"
            endIcon={<EastIcon />}
            sx={{
              mt: 8,
              border: '1px solid #76787A',
              color: '#76787A',
              borderRadius: '20px',
            }}
            LinkComponent={LinkI18n}
            href={'/coding/list/block'}
          >
            자세히 보기
          </Button>
        </Center>
      </Container>
    </Box>
  );
}
