export function isPathMatch(params: {
  exact?: string[] | string;
  starting?: string[] | string;
}): (path: string) => boolean {
  const exactArray = Array.isArray(params.exact)
    ? params.exact
    : typeof params.exact === 'string'
      ? [params.exact]
      : [];
  const exact = exactArray.filter(Boolean);

  const prefixArray: string[] = Array.isArray(params.starting)
    ? params.starting
    : typeof params.starting === 'string'
      ? [params.starting]
      : [];

  const prefixes = prefixArray.filter(Boolean);

  return (path: string) => {
    if (exact.some((it) => it === path)) {
      return true;
    }
    return prefixes.some((prefix) => path.startsWith(prefix));
  };
}
