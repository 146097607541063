import { createSvgIcon } from '@mui/material';

const HomeTopMenuFaqIcon = createSvgIcon(
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 30C19.713 30 23.274 28.525 25.8995 25.8995C28.525 23.274 30 19.713 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2C12.287 2 8.72601 3.475 6.1005 6.1005C3.475 8.72601 2 12.287 2 16C2 19.713 3.475 23.274 6.1005 25.8995C8.72601 28.525 12.287 30 16 30ZM12.88 9.08C13.74 8.372 14.868 7.95 16 7.95C18.434 7.95 20.68 9.59 20.68 12.23C20.68 12.984 20.522 13.72 20.084 14.43C19.668 15.108 19.058 15.658 18.334 16.164C17.9 16.47 17.682 16.678 17.576 16.82C17.5 16.924 17.5 16.96 17.5 16.998C17.5 17.3958 17.342 17.7774 17.0607 18.0587C16.7794 18.34 16.3978 18.498 16 18.498C15.6022 18.498 15.2206 18.34 14.9393 18.0587C14.658 17.7774 14.5 17.3958 14.5 16.998C14.5 15.41 15.588 14.426 16.612 13.708C17.172 13.316 17.416 13.044 17.532 12.858C17.6431 12.669 17.694 12.4506 17.678 12.232C17.678 11.632 17.192 10.95 16 10.95C15.5562 10.9602 15.1284 11.1178 14.784 11.398C14.45 11.672 14.322 11.97 14.322 12.232C14.322 12.6298 14.164 13.0114 13.8827 13.2927C13.6014 13.574 13.2198 13.732 12.822 13.732C12.4242 13.732 12.0426 13.574 11.7613 13.2927C11.48 13.0114 11.322 12.6298 11.322 12.232C11.322 10.886 12.012 9.792 12.882 9.078M18 22C18 22.5304 17.7893 23.0391 17.4142 23.4142C17.0391 23.7893 16.5304 24 16 24C15.4696 24 14.9609 23.7893 14.5858 23.4142C14.2107 23.0391 14 22.5304 14 22C14 21.4696 14.2107 20.9609 14.5858 20.5858C14.9609 20.2107 15.4696 20 16 20C16.5304 20 17.0391 20.2107 17.4142 20.5858C17.7893 20.9609 18 21.4696 18 22Z"
      fill="#039BE5"
    />
  </svg>,
  'HomeTopMenuFaqIcon',
);

export default HomeTopMenuFaqIcon;
