'use client';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import * as React from 'react';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

export function ElevationScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 300,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, { elevation: trigger ? 1 : 0 } as any);
}
