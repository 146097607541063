'use client';

import type { SimplePaletteColorOptions as MuiSimplePaletteColorOptions } from '@mui/material';
import { BrowserStorageWrapper } from '@ocodelib/ex-util';
import { deepEq } from '@ocodelib/util/misc';
import { safeGetLocalStorage } from '@repo-foundation/util/DummyStorage';

export type DynamicThemePayload = {
  palette?: {
    primary?: MuiSimplePaletteColorOptions;
    secondary?: MuiSimplePaletteColorOptions;
    error?: MuiSimplePaletteColorOptions;
    warning?: MuiSimplePaletteColorOptions;
    info?: MuiSimplePaletteColorOptions;
    success?: MuiSimplePaletteColorOptions;
  };
};

export const DynamicThemePreference = new BrowserStorageWrapper<DynamicThemePayload>({
  storage: safeGetLocalStorage,
  storageKey: 'DynamicThemePreference',
  serialize: JSON.stringify,
  deserialize: JSON.parse,
  equal: deepEq,
});
