import { createSvgIcon } from '@mui/material';

const NaverCafeIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.8258 13.9474C17.8258 17.8418 14.6686 20.9996 10.7742 20.9996C6.87983 20.9996 3.72266 17.8418 3.72266 13.9474V9.90088H17.8258V13.9474Z"
      fill="currentColor"
    />
    <mask id="path-2-inside-1_1477_21070" fill="white">
      <path d="M17.8258 9.90088C18.6337 9.90088 19.4085 10.2218 19.9797 10.7931C20.551 11.3643 20.8719 12.1391 20.8719 12.947C20.8719 13.7548 20.551 14.5296 19.9797 15.1009C19.4085 15.6721 18.6337 15.993 17.8258 15.993H17.5239" />
    </mask>
    <path
      d="M17.8258 8.08954C16.8254 8.08954 16.0145 8.9005 16.0145 9.90088C16.0145 10.9013 16.8254 11.7122 17.8258 11.7122V8.08954ZM17.8258 15.993V17.8044V15.993ZM17.5239 14.1817C16.5235 14.1817 15.7126 14.9927 15.7126 15.993C15.7126 16.9934 16.5235 17.8044 17.5239 17.8044V14.1817ZM17.8258 11.7122C18.1533 11.7122 18.4673 11.8423 18.6989 12.0739L21.2605 9.51224C20.3496 8.6013 19.1141 8.08954 17.8258 8.08954V11.7122ZM18.6989 12.0739C18.9305 12.3054 19.0605 12.6195 19.0605 12.947H22.6832C22.6832 11.6587 22.1715 10.4232 21.2605 9.51224L18.6989 12.0739ZM19.0605 12.947C19.0605 13.2744 18.9305 13.5885 18.6989 13.82L21.2605 16.3817C22.1715 15.4707 22.6832 14.2352 22.6832 12.947H19.0605ZM18.6989 13.82C18.4673 14.0516 18.1533 14.1817 17.8258 14.1817V17.8044C19.1141 17.8044 20.3496 17.2926 21.2605 16.3817L18.6989 13.82ZM17.8258 14.1817H17.5239V17.8044H17.8258V14.1817Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_1477_21070)"
    />
    <path
      d="M15.9972 3.10938C15.9972 4.48604 15.4503 5.80631 14.4768 6.77976C13.5034 7.7532 12.1831 8.30008 10.8065 8.30008H7.20068C7.20068 6.92342 7.74756 5.60314 8.72101 4.6297C9.69445 3.65625 11.0147 3.10938 12.3914 3.10938H15.9972Z"
      fill="currentColor"
    />
  </svg>,
  'NaverCafeIcon',
);

export default NaverCafeIcon;
