import type { CSSProperties } from 'react';

function setProp_(
  result: Record<string, any> | undefined,
  name: string,
  value: any,
): Record<string, any> | undefined {
  if (typeof value === 'undefined' || value === null) {
    return result;
  }
  const r = result || {};
  r[name] = value;
  return r;
}

export function sxDisplayNone(isApply: boolean) {
  if (isApply) {
    return { display: 'none' };
  }
  return undefined;
}

export function sxLineClamp(lineCount: number) {
  return {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': lineCount,
  };
}

export function sxChildSpaceX(
  space: CSSProperties['marginLeft'],
  selector = '*',
  force = true,
): CSSProperties {
  return force
    ? ({
        [`&& > ${selector} + ${selector}`]: {
          marginLeft: space,
        },
      } as any)
    : {
        [`& > ${selector} + ${selector}`]: {
          marginLeft: space,
        },
      };
}

export function sxChildSpaceY(
  space: CSSProperties['marginTop'],
  selector = '*',
  force = true,
): CSSProperties {
  return force
    ? ({
        [`&& > ${selector} + ${selector}`]: {
          marginTop: space,
        },
      } as any)
    : {
        [`& > ${selector} + ${selector}`]: {
          marginTop: space,
        },
      };
}

export function sxFlexRowCenter(option?: {
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  wrap?: CSSProperties['flexWrap'];
}): CSSProperties {
  let opt = undefined as Record<string, any> | undefined;
  if (option) {
    opt = setProp_(opt, 'alignItems', option.align);
    opt = setProp_(opt, 'justifyContent', option.justify);
    opt = setProp_(opt, 'flexWrap', option.wrap);
  }
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    ...opt,
  };
}

export function sxFlexColumnCenter(option?: {
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  wrap?: CSSProperties['flexWrap'];
}): CSSProperties {
  let opt = undefined as Record<string, any> | undefined;
  if (option) {
    opt = setProp_(opt, 'alignItems', option.align);
    opt = setProp_(opt, 'justifyContent', option.justify);
    opt = setProp_(opt, 'flexWrap', option.wrap);
  }
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ...opt,
  };
}

export function sxFlexRow(option?: {
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  wrap?: CSSProperties['flexWrap'];
}): CSSProperties {
  let opt = undefined as Record<string, any> | undefined;
  if (option) {
    opt = setProp_(opt, 'alignItems', option.align);
    opt = setProp_(opt, 'justifyContent', option.justify);
    opt = setProp_(opt, 'flexWrap', option.wrap);
  }
  return {
    display: 'flex',
    flexDirection: 'row',
    ...opt,
  };
}

export function sxFlexColumn(option?: {
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  wrap?: CSSProperties['flexWrap'];
}): CSSProperties {
  let opt = undefined as Record<string, any> | undefined;
  if (option) {
    opt = setProp_(opt, 'alignItems', option.align);
    opt = setProp_(opt, 'justifyContent', option.justify);
    opt = setProp_(opt, 'flexWrap', option.wrap);
  }
  return {
    display: 'flex',
    flexDirection: 'column',
    ...opt,
  };
}

export function sxAbsoluteCenter(option?: {
  transform?: CSSProperties['transform'];
}): CSSProperties {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: option?.transform || 'translate(-50%, -50%)',
  };
}

export function sxAbsolute(option?: {
  top?: CSSProperties['top'];
  left?: CSSProperties['left'];
  right?: CSSProperties['right'];
  bottom?: CSSProperties['bottom'];
  content?: CSSProperties['content'];
  transform?: CSSProperties['transform'];
  zIndex?: CSSProperties['zIndex'];
}): CSSProperties {
  let opt = undefined as Record<string, any> | undefined;
  if (option) {
    opt = setProp_(opt, 'top', option.top);
    opt = setProp_(opt, 'left', option.left);
    opt = setProp_(opt, 'right', option.right);
    opt = setProp_(opt, 'bottom', option.bottom);
    opt = setProp_(opt, 'content', option.content);
    opt = setProp_(opt, 'transform', option.transform);
    opt = setProp_(opt, 'zIndex', option.zIndex);
  }

  return {
    position: 'absolute',
    ...opt,
  };
}

export function sxAbsoluteFull(option?: {
  top?: CSSProperties['top'];
  left?: CSSProperties['left'];
  right?: CSSProperties['right'];
  bottom?: CSSProperties['bottom'];
  content?: CSSProperties['content'];
  transform?: CSSProperties['transform'];
  zIndex?: CSSProperties['zIndex'];
}): CSSProperties {
  let opt = undefined as Record<string, any> | undefined;
  opt = setProp_(opt, 'top', option?.top ?? 0);
  opt = setProp_(opt, 'left', option?.left ?? 0);
  opt = setProp_(opt, 'right', option?.right ?? 0);
  opt = setProp_(opt, 'bottom', option?.bottom ?? 0);
  opt = setProp_(opt, 'content', option?.content);
  opt = setProp_(opt, 'transform', option?.transform);
  opt = setProp_(opt, 'zIndex', option?.zIndex);

  return {
    position: 'absolute',
    ...opt,
  };
}

export function sxWidth(option?: {
  w?: CSSProperties['width'];
  min?: CSSProperties['minWidth'];
  max?: CSSProperties['maxWidth'];
}): CSSProperties | undefined {
  let opt = undefined as Record<string, any> | undefined;
  if (option) {
    opt = setProp_(opt, 'width', option.w);
    opt = setProp_(opt, 'minWidth', option.min);
    opt = setProp_(opt, 'maxWidth', option.max);
  }

  return opt;
}

export function sxHeight(option?: {
  h?: CSSProperties['height'];
  min?: CSSProperties['minHeight'];
  max?: CSSProperties['maxHeight'];
}): CSSProperties | undefined {
  let opt = undefined as Record<string, any> | undefined;
  if (option) {
    opt = setProp_(opt, 'height', option.h);
    opt = setProp_(opt, 'minHeight', option.min);
    opt = setProp_(opt, 'maxHeight', option.max);
  }

  return opt;
}

export function sxDim(option?: {
  w?: CSSProperties['width'];
  h?: CSSProperties['height'];
  minW?: CSSProperties['minWidth'];
  maxW?: CSSProperties['maxWidth'];
  minH?: CSSProperties['minHeight'];
  maxH?: CSSProperties['maxHeight'];
}): CSSProperties | undefined {
  let opt = undefined as Record<string, any> | undefined;
  if (option) {
    opt = setProp_(opt, 'width', option.w);
    opt = setProp_(opt, 'minWidth', option.minW);
    opt = setProp_(opt, 'maxWidth', option.maxW);
    opt = setProp_(opt, 'height', option.h);
    opt = setProp_(opt, 'minHeight', option.minH);
    opt = setProp_(opt, 'maxHeight', option.maxH);
  }

  return opt;
}
