import { TinyColor } from '@ctrl/tinycolor';
import type { SimplePaletteColorOptions as MuiSimplePaletteColorOptions } from '@mui/material';

export type PaletteColorInfo = {
  main: string;
  light: string;
  dark: string;
  contrastText: string;
  mainChannel: string;
  lightChannel: string;
  darkChannel: string;
  contrastTextChannel: string;
};

export type CustomPaletteName =
  | string
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

/**
 * TinyColor 인스턴스를 생성합니다.
 * @param color - 처리할 색상 코드 (예: #f44336, rgb(244,67,54))
 * @returns TinyColor 인스턴스
 */
function tinycolor(color: string) {
  return new TinyColor(color);
}

/**
 * 메인 색상을 기준으로 테마 색상(밝은 색상, 어두운 색상, 대비 텍스트 색상 등)을 생성합니다.
 * @param colorOption - 색상 정의 객체 (MuiSimplePaletteColorOptions 타입)
 * @returns 생성된 테마 색상과 채널 값을 포함한 객체
 */
export const generateColorOptions = (
  colorOption: MuiSimplePaletteColorOptions,
): PaletteColorInfo => {
  const main = tinycolor(colorOption.main);
  const light = colorOption.light ? tinycolor(colorOption.light) : main.lighten(20); // 사용자가 제공하거나 기본 계산
  const dark = colorOption.dark ? tinycolor(colorOption.dark) : main.darken(20); // 사용자가 제공하거나 기본 계산
  const contrastText = colorOption.contrastText
    ? tinycolor(colorOption.contrastText)
    : main.isLight()
      ? tinycolor('#000')
      : tinycolor('#fff'); // 사용자가 제공하거나 기본값

  /**
   * TinyColor 객체를 RGB 채널 문자열로 변환합니다.
   * @param color - TinyColor 인스턴스
   * @returns RGB 채널 문자열 (예: "244 67 54")
   */
  const toChannel = (color: TinyColor) => {
    const rgb = color.toRgb();
    return `${rgb.r} ${rgb.g} ${rgb.b}`;
  };

  return {
    main: main.toHexString(), // 메인 색상의 16진수 코드
    light: light.toHexString(), // 밝은 색상의 16진수 코드
    dark: dark.toHexString(), // 어두운 색상의 16진수 코드
    contrastText: contrastText.toHexString(), // 대비 텍스트 색상의 16진수 코드
    mainChannel: toChannel(main), // 메인 색상의 RGB 채널 값
    lightChannel: toChannel(light), // 밝은 색상의 RGB 채널 값
    darkChannel: toChannel(dark), // 어두운 색상의 RGB 채널 값
    contrastTextChannel: toChannel(contrastText), // 대비 텍스트 색상의 RGB 채널 값
  };
};

/**
 * 주어진 테마 색상을 기반으로 CSS 변수를 생성합니다.
 * @param paletteName - 팔레트 이름 (예: primary, secondary)
 * @param colorOption - 색상 정의 객체 (MuiSimplePaletteColorOptions 타입)
 * @returns CSS 변수 객체 (예: { "--mui-palette-primary-main": "#f44336" })
 */
export const createColorVariables = (
  paletteName: CustomPaletteName,
  colorOption: MuiSimplePaletteColorOptions,
) => {
  const result = {} as Record<string, string>;
  for (const [key, color] of Object.entries(generateColorOptions(colorOption))) {
    result[`--mui-palette-${paletteName}-${key}`] = color;
  }
  return result;
};

export const createColorVarsByColorInfo = (
  paletteName: CustomPaletteName,
  info: PaletteColorInfo,
) => {
  const result = {} as Record<string, string>;
  for (const [key, color] of Object.entries(info)) {
    result[`--mui-palette-${paletteName}-${key}`] = color;
  }
  return result;
};
