import { alpha, Box, ButtonBase, Typography, type SxProps } from '@mui/material';
import { flatSx } from '@ocodelib/mui';
import clsx from 'clsx';
import Scrollbars from 'react-custom-scrollbars-2';
import { ItemNode } from '../types';

interface Props {
  sx?: SxProps;
  className?: string;
  selectedItemId?: number;
  bookItems: ItemNode[];
  onChangeItem: (itemId: number) => void;
}

export function LeftTocList(props: Props) {
  const { sx, className, selectedItemId, bookItems, onChangeItem } = props;

  const handleClickDoc = (itemId: number) => () => {
    onChangeItem(itemId);
  };

  const handleClickChildDoc = (itemId: number) => {
    onChangeItem(itemId);
  };

  return (
    <Box
      className={clsx('LeftTocList-root', className)}
      sx={flatSx(
        {
          color: '#444',
          '& .ChapterRow-root': {
            mt: 3,
            mr: 1,
          },
          '& .DocRow-root': {
            pl: 2,
            pr: 2,
          },
        },
        sx,
      )}
    >
      <Scrollbars>
        {bookItems.map((it) => {
          if (it.itemType === 'chapter') {
            return (
              <ChapterRow
                key={it.itemId}
                item={it}
                selectedItemId={selectedItemId}
                onClickChildDoc={handleClickChildDoc}
              />
            );
          }
          return (
            <DocRow
              key={it.itemId}
              item={it}
              groupSeq={undefined}
              selected={it.itemId === selectedItemId}
              onClick={handleClickDoc(it.itemId)}
            />
          );
        })}
      </Scrollbars>
    </Box>
  );
}

function ChapterRow(props: {
  item: ItemNode;
  selectedItemId?: number;
  onClickChildDoc: (itemId: number) => void;
}) {
  const { item, selectedItemId, onClickChildDoc } = props;
  const { title, seq, children } = item;

  const handleClickDoc = (itemId: number) => () => {
    onClickChildDoc(itemId);
  };

  return (
    <Box
      className="ChapterRow-root"
      data-item-id={item.itemId}
      sx={{
        position: 'relative',
        '& .ChapterRow-title': {
          bgcolor: '#fafafa',
          py: 1,
          px: 1,
          fontSize: '0.75rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
    >
      <Typography className="ChapterRow-title" component="div" variant="body2">
        {seq}. {title}
      </Typography>
      <Box>
        {children.map((it) => (
          <DocRow
            key={it.itemId}
            groupSeq={seq}
            item={it}
            selected={it.itemId === selectedItemId}
            onClick={handleClickDoc(it.itemId)}
          />
        ))}
      </Box>
    </Box>
  );
}

function DocRow(props: {
  groupSeq?: number;
  item: {
    itemId: number;
    itemType: 'chapter' | 'doc';
    title: string;
    seq: number;
  };
  selected: boolean;
  onClick: React.MouseEventHandler;
}) {
  const { item, selected, groupSeq, onClick } = props;
  const { title, seq } = item;

  return (
    <ButtonBase
      data-item-id={item.itemId}
      component="div"
      disableRipple={false}
      onClick={onClick}
      className={clsx('DocRow-root', { x_selected: selected })}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        py: 1.5,
        '& .DocRow-title': {
          color: '#666',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },

        '&.x_selected': {
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
          '& .DocRow-title': {
            color: 'primary.main',
          },
          '&::before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            bottom: 0,
            width: 4,
            bgcolor: 'primary.main',
          },
        },
      }}
    >
      <Typography className="DocRow-title" component="span">
        {groupSeq}.{seq}. {title}
      </Typography>
    </ButtonBase>
  );
}
