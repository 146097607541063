import DashboardIcon from '@mui/icons-material/Dashboard';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import TimelineIcon from '@mui/icons-material/Timeline';
import { isPathMatch } from '@repo-foundation/util/menu-path-util';
import * as nav from '../../nav';

export const mainNavManager: nav.MenuManager = nav.configureSideMenus([
  {
    type: 'menu',
    icon: <DashboardIcon />,
    title: '홈',
    href: '/',
  },
  {
    type: 'menu',
    icon: <DriveFolderUploadIcon />,
    title: '실험실',
    href: '/dev-labs',
  },
  {
    type: 'menu',
    icon: <DriveFolderUploadIcon />,
    title: '스타일보드',
    href: '/style-board',
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: '교과목',
    submenus: [
      {
        type: 'menu',
        title: '초등',
        href: '/dtb/list/A01',
        match: isPathMatch({ exact: '/dtb/list/A01', starting: '/dtb/list/A01/' }),
      },

      {
        type: 'menu',
        title: '중등',
        href: '/dtb/list/B01',
        match: isPathMatch({ exact: '/dtb/list/B01', starting: '/dtb/list/B01/' }),
      },

      {
        type: 'menu',
        title: '고등',
        href: '/dtb/list/C01',
        match: isPathMatch({ exact: '/dtb/list/C01', starting: '/dtb/list/C01/' }),
      },
    ],
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: 'AI학습',
    submenus: [
      {
        type: 'menu',
        title: 'AI학습',
        href: '/aitrain/aitrain',
      },

      {
        type: 'menu',
        title: '데이터 과학',
        href: '/aitrain/data_science',
      },
    ],
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: '코딩',
    submenus: [
      {
        type: 'menu',
        title: '코딩하기',
        href: '/coding/dashboard',
      },
      {
        type: 'menu',
        title: '튜터',
        href: '/tutor/intro',
      },
      {
        type: 'menu',
        title: '나의 코딩',
        href: '/coding/mine',
      },
    ],
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: '커뮤니티',
    submenus: [
      {
        type: 'menu',
        title: '클래스룸',
        href: '/classroom',
      },

      {
        type: 'menu',
        title: '스터디그룹',
        href: '/studygroup',
      },

      {
        type: 'menu',
        title: '오픈강좌',
        href: '/openlecture',
        match: isPathMatch({ exact: '/openlecture', starting: '/openlecture/' }),
      },

      {
        type: 'menu',
        title: '갤러리',
        href: '/gallery/list',
        match: isPathMatch({ exact: '/gallery/list', starting: '/gallery/list/' }),
      },
    ],
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: 'About Us',
    submenus: [
      {
        type: 'menu',
        title: 'O-CODE',
        href: '/intro',
      },

      {
        type: 'menu',
        title: '공지사항',
        href: '/notice',
        match: isPathMatch({ exact: '/notice', starting: '/notice/' }),
      },
      {
        type: 'menu',
        title: '문의',
        href: '/ask/qna/list',
        match: isPathMatch({ starting: '/ask/' }),
      },
    ],
  },
]);
