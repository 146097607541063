import { PathHolder } from '@repo-foundation/util/path-utils';
import { EventEmitter } from 'eventemitter3';

interface CustomRouterEventMap {
  push: (link: string) => void;
  back: () => void;
}

/**
 * 라우터 이벤트
 */
export const customRouterEvents = new EventEmitter<CustomRouterEventMap>();

/**
 * locale prefix가 자동으로 추가되는 라우터 이벤트
 */
export const routerI18nEvents = new EventEmitter<CustomRouterEventMap>();

/**
 * ex) concatPaths('a', 'b', 'c') => '/a/b/c'
 * ex) concatPaths('/en/py/list') => '/en/py/list'
 * ex) concatPaths('en/py/list') => '/en/py/list'
 * ex) concatPaths('en', 'py','list') => '/en/py/list'
 * ex) concatPaths('en', 'py', undefined,'list') => '/en/py/list'
 * ex) concatPaths('en', 'py', null, 'list') => '/en/py/list'
 * ex) concatPaths('en', 'py/list') => '/en/py/list'
 * ex) concatPaths('en', '/py/list') => '/en/py/list'
 * ex) concatPaths('en', '//py/list') => '/en/py/list'
 * ex) concatPaths('en','/','py','list') => '/en/py/list'
 * ex) concatPaths('en', '//py/list/') => '/en/py/list'
 */
export function concatPaths(...extraLinks: (string | number | undefined | null)[]) {
  if (extraLinks.length === 0) return '/';
  return '/' + PathHolder.from(...extraLinks.map((it) => '' + it)).path;
}

/**
 * ex) routerPush('a', 'b', 'c') => '/a/b/c'
 * ex) routerPush('/en/py/list') => '/en/py/list'
 * ex) routerPush('en/py/list') => '/en/py/list'
 * ex) routerPush('en', 'py','list') => '/en/py/list'
 * ex) routerPush('en', 'py', undefined,'list') => '/en/py/list'
 * ex) routerPush('en', 'py', null, 'list') => '/en/py/list'
 * ex) routerPush('en', 'py/list') => '/en/py/list'
 * ex) routerPush('en', '/py/list') => '/en/py/list'
 * ex) routerPush('en', '//py/list') => '/en/py/list'
 * ex) routerPush('en','/','py','list') => '/en/py/list'
 * ex) routerPush('en', '//py/list/') => '/en/py/list'
 */
export function routerPush(...extraLinks: (string | number | undefined | null)[]) {
  customRouterEvents.emit('push', concatPaths(...extraLinks));
}

export function routerBack() {
  customRouterEvents.emit('back');
}

/**
 * routerPush()와 동일하게 동작하며, locale prefix가 자동으로 추가됩니다.
 * ex) routerPushI18n('a', 'b', 'c') => '/en/a/b/c'
 * ex) routerPushI18n('/py/list') => '/en/py/list'
 * ex) routerPushI18n('py/list') => '/en/py/list'
 * ex) routerPushI18n('py','list') => '/en/py/list'
 * ex) routerPushI18n('py', undefined,'list') => '/en/py/list'
 * ex) routerPushI18n('py', null, 'list') => '/en/py/list'
 * ex) routerPushI18n('py/list') => '/en/py/list'
 * ex) routerPushI18n('/py/list') => '/en/py/list'
 * ex) routerPushI18n('//py/list') => '/en/py/list'
 * ex) routerPushI18n('/','py','list') => '/en/py/list'
 * ex) routerPushI18n( '//py/list/') => '/en/py/list'
 */
export function routerPushI18n(...extraLinks: (string | number | undefined | null)[]) {
  routerI18nEvents.emit('push', concatPaths(...extraLinks));
}

export function routerBackI18n() {
  routerI18nEvents.emit('back');
}
