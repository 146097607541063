import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Box, ButtonBase, IconButton, Paper, Typography } from '@mui/material';
import { Center, FlexRow } from '@ocodelib/mui';
import NextImage from 'next/image';
import type { MouseEventHandler } from 'react';
import { toast } from 'react-toastify';

interface Props {
  imgUrl: string;
  title: string;
  onClick: MouseEventHandler;
}

export default function AiTechCard(props: Props) {
  const { imgUrl, title, onClick } = props;
  return (
    <Paper
      className="AiTechCard-root"
      sx={{
        borderRadius: '8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        boxShadow: '2px 4px 10px rgba(0,0,0,0.25)',
      }}
      onClick={onClick}
    >
      {/* img */}
      <ButtonBase
        className="AiTechCard-imgBox"
        onClick={() => toast.warn('준비중입니다.')}
        sx={{
          position: 'relative',
          width: '100%',
          height: 210,
          '& img': {
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          },
        }}
      >
        {/* tag */}
        <FlexRow
          alignItems="center"
          className="AiTechCard-top"
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            height: 24,
            px: 1.5,
            bgcolor: 'primary.main',
            borderRadius: '20px',
            fontSize: '0.7rem',
            color: '#fff',
          }}
        >
          GPU
        </FlexRow>
        <NextImage src={imgUrl} alt="" width={391} height={294} />
      </ButtonBase>

      {/* AiTechCard-bottom */}
      <Box
        className="AltechCard-bottom"
        sx={{ position: 'relative', width: '100%', py: '10px', pl: 2, pr: '12px' }}
      >
        <Center>
          <Typography fontWeight={700} fontSize="1.125rem">
            {title}
          </Typography>

          {/* <Button
            color='inherit'
            variant='text'
            size="small"
            endIcon={<ChevronRightOutlinedIcon />}
            sx={{ color: '#76787A', fontSize: '0.7rem', px: 0 }}
          >
            자세히 보기
          </Button> */}
        </Center>
        <IconButton
          onClick={() => toast.warn('준비중입니다.')}
          sx={{
            position: 'absolute',
            top: 5,
            right: 0,
          }}
        >
          <ChevronRightOutlinedIcon />
        </IconButton>
      </Box>
    </Paper>
  );
}
