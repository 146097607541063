import type { PaletteOptions } from '@mui/material';
import { CUSTOM_PALETTES } from './custom-palette';

// export const palette: PaletteOptions = {
//   mode: 'light',
//   primary: {
//     contrastText: '#fff',
//     light: '#4dc4ff',
//     main: '#00ACFF',
//     dark: '#0c89db',
//   },
//   secondary: {
//     contrastText: '#FFF',
//     light: '#f15e8f',
//     main: '#ea0e5e',
//     dark: '#c30757',
//   },
// };

export const palette: PaletteOptions = {
  mode: 'light',
  primary: CUSTOM_PALETTES.blue,
  secondary: CUSTOM_PALETTES.pink,
  error: CUSTOM_PALETTES.red,
};
