import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMainLayoutStore } from '../../../../store/useMainLayoutStore';

/**
 * Large 화면에서 왼쪽 메뉴가 기본으로 열리도록 체크하는 컴포넌트
 */
export function SidebarAutoOpenCheck() {
  const mainLayoutStore = useMainLayoutStore();
  const theme = useTheme();
  const smOrDown = useMediaQuery(theme.breakpoints.down('md'));
  const isAutoOpenChecked = mainLayoutStore.isSidebarAutoOpenChecked;
  const isSidebarOpen = mainLayoutStore.isSidebarOpened;
  const [prepared, setPrepared] = useState(false);

  useEffect(() => {
    setPrepared(true);
  }, []);

  useEffect(() => {
    if (!prepared) return;
    if (isAutoOpenChecked) return;

    if (!smOrDown && !isSidebarOpen) {
      mainLayoutStore.setSidebarOpened(true);
    }
    mainLayoutStore.setSidebarAutoOpenChecked(true);
  }, [prepared, smOrDown, isAutoOpenChecked, mainLayoutStore, isSidebarOpen]);

  return null;
}
