import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import { PathUtils } from '../lib/path-utils';
import type { DatiFile, DatiFolderTreeNode } from '../model/dati/index';
import { overrideReqCtxConfig } from '../util/override-req-ctx-config';

/**
 * 다티 Api
 */
export class DatiApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  ls = (
    params: {
      datiId: string;
      path?: string;
    } & BaseRequest,
  ): Promise<{ fileList: DatiFile[] }> => {
    const { datiId, path, ...rest } = params;
    const url = `/admin/dati/${datiId}/ls/${enc_(path)}`;
    return this.withData.get(url, ...splitParams(rest));
  };

  lsFiles = (
    params: {
      datiId: string;
      path?: string;
    } & BaseRequest,
  ): Promise<{ fileList: DatiFile[] }> => {
    const { datiId, path, ...rest } = params;
    const url = `/admin/dati/${datiId}/ls-files/${enc_(path)}`;
    return this.withData.get(url, ...splitParams(rest));
  };

  lsFolders = (
    params: {
      datiId: string;
      path?: string;
    } & BaseRequest,
  ): Promise<{ fileList: DatiFile[] }> => {
    const { datiId, path, ...rest } = params;
    const url = `/admin/dati/${datiId}/ls-folders/${enc_(path)}`;
    return this.withData.get(url, ...splitParams(rest));
  };

  folderTree = (
    params: {
      datiId: string;
      path?: string;
    } & BaseRequest,
  ): Promise<{ tree: DatiFolderTreeNode[] }> => {
    const { datiId, path, ...rest } = params;
    const url = `/admin/dati/${datiId}/folder-tree/${enc_(path)}`;
    return this.withData.get(url, ...splitParams(rest));
  };

  folderPathTree = (
    params: {
      datiId: string;
      path?: string;
    } & BaseRequest,
  ): Promise<{ tree: string[] }> => {
    const { datiId, path, ...rest } = params;
    const url = `/admin/dati/${datiId}/folder-path-tree/${enc_(path)}`;
    return this.withData.get(url, ...splitParams(rest));
  };

  rename = (
    params: {
      datiId: string;
      path: string;
      newName: string;
    } & BaseRequest,
  ): Promise<{ file: DatiFile }> => {
    const { datiId, path, ...rest } = params;
    const url = `/admin/dati/${datiId}/rename/${enc_(path)}`;
    return this.withData.post(url, ...splitParams(rest));
  };

  create = (
    params: {
      datiId: string;
      path: string;
      file: File;
      overwrite: boolean;
    } & BaseRequest,
  ): Promise<{ file: DatiFile }> => {
    const { datiId, path, file, overwrite } = params;
    const url = `/admin/dati/${datiId}/create/${enc_(path)}`;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('overwrite', overwrite ? 'true' : 'false');

    // no timeout
    const ctx = overrideReqCtxConfig(params.ctx, { timeout: 0 });
    return this.withData.postMultipart(url, formData, { ctx });
  };

  mkdir = (
    params: {
      datiId: string;
      path: string;
      intermediate: boolean;
      overwrite: boolean;
    } & BaseRequest,
  ): Promise<{ file: DatiFile }> => {
    const { datiId, path, ...rest } = params;
    const url = `/admin/dati/${datiId}/mkdir/${enc_(path)}`;
    return this.withData.post(url, ...splitParams(rest));
  };

  /**
   * 파일 또는 폴더 삭제
   * 폴더인 경우 recursive하게 삭제된다
   */
  rm = async (
    params: {
      datiId: string;
      path: string;
    } & BaseRequest,
  ): Promise<void> => {
    const { datiId, path, ...rest } = params;
    const url = `/admin/dati/${datiId}/rm/${enc_(path)}`;
    await this.helper.post(url, ...splitParams(rest));
  };

  /**
   * 자식 파일 또는 폴더를 여러개 파라미터로 전송하여 삭제한다
   * 폴더인 경우 recursive하게 삭제된다
   */
  rmChildren = async (
    params: {
      datiId: string;
      path: string;
      children: string[];
    } & BaseRequest,
  ): Promise<void> => {
    const { datiId, path, ...rest } = params;
    const url = `/admin/dati/${datiId}/rm-children/${enc_(path)}`;
    await this.helper.post(url, ...splitParams(rest));
  };

  moveChildren = async (
    params: {
      datiId: string;
      srcParent: string;
      dstParent: string;
      itemNames: string[];
    } & BaseRequest,
  ): Promise<void> => {
    const { datiId, ...rest } = params;
    const url = `/admin/dati/${datiId}/move-children`;
    await this.helper.postJson(url, ...splitParams(rest));
  };

  copyChildren = async (
    params: {
      datiId: string;
      srcParent: string;
      dstParent: string;
      itemNames: string[];
    } & BaseRequest,
  ): Promise<void> => {
    const { datiId, ...rest } = params;
    const url = `/admin/dati/${datiId}/copy-children`;
    await this.helper.postJson(url, ...splitParams(rest));
  };

  textContents = (
    params: {
      datiId: string;
      path: string;
    } & BaseRequest,
  ): Promise<string> => {
    const { datiId, path } = params;
    const url = `/ds/${datiId}/${enc_(path)}`;
    return this.helper.server
      .post(url, undefined, {
        responseType: 'text',
      })
      .then((response) => response.data as string);
  };

  jsonContents = (
    params: {
      datiId: string;
      path: string;
    } & BaseRequest,
  ): Promise<any> => {
    const { datiId, path } = params;
    const url = `/ds/${datiId}/${enc_(path)}`;
    return this.helper.server
      .post(url, undefined, {
        responseType: 'json',
      })

      .then((response) => response.data);
  };

  blobContents = (
    params: {
      datiId: string;
      path: string;
    } & BaseRequest,
  ): Promise<Blob> => {
    const { datiId, path } = params;
    const url = `/ds/${datiId}/${enc_(path)}`;
    return this.helper.server
      .post(url, undefined, {
        responseType: 'blob',
      })
      .then((response) => response.data as Blob);
  };

  bufferContents = (
    params: {
      datiId: string;
      path: string;
    } & BaseRequest,
  ): Promise<Uint8Array> => {
    const { datiId, path } = params;
    const url = `/ds/${datiId}/${enc_(path)}`;
    return this.helper.server
      .post(url, undefined, {
        responseType: 'arraybuffer',
      })
      .then((response) => response.data as Uint8Array);
  };
}

function enc_(path?: string): string {
  return PathUtils.split(path)
    .map((it) => encodeURIComponent(it))
    .join('/');
}
