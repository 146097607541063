'use client';

import { Box, styled, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { FlexColumn } from '@ocodelib/mui';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { CustomBreadcrumbs, type CustomBreadcrumbsProps } from '../../components/CustomBreadcrumbs';
import { ElevationScroll } from '../../components/ElevationScroll';
import { useMainLayoutStore } from '../../store/useMainLayoutStore';
import DrawerHeader from './components/DrawerHeader';
import Footer from './components/Footer';
import Sidebar from './components/sidebar/Sidebar';
import MainLayoutTopbar from './components/topbar/MainLayoutTopbar';
import { mainLayoutConstants as LAYOUT } from './main-layout-constants';

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'contentShift',
})<{
  contentShift: number;
}>(({ theme, contentShift }) => ({
  flexGrow: 1,
  position: 'relative',
  padding: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: 0,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100dvh',
  backgroundColor: LAYOUT.contentBgColor,
}));

interface Props {
  title?: string;
  hideTopbar?: boolean;
  hideFooter?: boolean;
  hideLoginButton?: boolean;
  children?: ReactNode;
  breadcrumbProps?: CustomBreadcrumbsProps;
}

export default function MainLayout(props: Props) {
  const {
    title,
    hideTopbar = false,
    hideLoginButton = false,
    children,
    breadcrumbProps,
    hideFooter = false,
  } = props;
  const mainLayoutStore = useMainLayoutStore((state) => state);
  const isMobile = useMediaQuery('(max-width:1200px)');
  const isSidebarOpen = mainLayoutStore.isSidebarOpened;

  useEffect(() => {
    if (!isMobile && isSidebarOpen) {
      mainLayoutStore.setSidebarOpened(false);
    }
  }, [isMobile, isSidebarOpen, mainLayoutStore]);

  return (
    <Box
      className="MainLayout-root"
      sx={{
        position: 'relative',
        minHeight: '100dvh',
      }}
    >
      {!hideTopbar && (
        <ElevationScroll>
          <MainLayoutTopbar
            isMobile={isMobile}
            isSidebarOpen={isSidebarOpen}
            hideLoginButton={hideLoginButton}
            onClickMenuButton={() => {
              mainLayoutStore.toggleSidebarOpen();
            }}
          />
        </ElevationScroll>
      )}

      {isMobile && (
        <Drawer
          sx={{
            width: LAYOUT.sidemenu.width,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: LAYOUT.sidemenu.width,
              boxSizing: 'border-box',
              backgroundColor: LAYOUT.sidemenu.bgColor,
              color: 'white',
            },
          }}
          variant="temporary"
          anchor="left"
          open={isSidebarOpen}
          onClose={() => {
            mainLayoutStore.setSidebarOpened(false);
          }}
        >
          <Sidebar isMobile={isMobile} />
        </Drawer>
      )}

      <Main contentShift={0} className="MainLayout-bodyContainer">
        {!hideTopbar && <DrawerHeader />}
        {breadcrumbProps && <CustomBreadcrumbs {...breadcrumbProps} />}
        <FlexColumn
          className="MainLayout-body"
          sx={{
            flex: 1,
            '& > *:first-of-type': {
              flex: 1,
            },
          }}
        >
          {children}
        </FlexColumn>
      </Main>
      {!hideFooter && <Footer />}
    </Box>
  );
}
