'use client';

import type { SxProps } from '@mui/material';
import { Box, ButtonBase, IconButton, Typography, useMediaQuery } from '@mui/material';
import { flatSx, FlexRow, StackRow } from '@ocodelib/mui';
import { isBlank } from '@ocodelib/util/string';
import GithubIcon from '@repo-foundation/icons/social/GithubIcon';
import InstagramIcon from '@repo-foundation/icons/social/InstagramIcon';
import KakaoIcon from '@repo-foundation/icons/social/KakaoIcon';
import NaverCafeIcon from '@repo-foundation/icons/social/NaverCafeIcon';
import clsx from 'clsx';
import NextImage from 'next/image';
import type { MouseEventHandler, ReactNode } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { IframeDialog, type IframeDialogProps } from '../../../dialogs/IframeDialog';
import { OpenSourceDialog, type OpenSourceDialogProps } from '../../../dialogs/OpenSourceDialog';
import { apiUrlOf } from '../../../util/urls';

interface Props {
  sx?: SxProps;
  className?: string;
}

export const clauseUrls = {
  /**
   * 약관 정보 URL
   */
  view: (clauseId: number): string => apiUrlOf(`/p/m/clause/${clauseId}.html`),

  // 이용약관
  viewUsage: (): string => apiUrlOf('/p/m/clause/1000004.html'),

  // 개인정보 처리방침
  viewPrivacy: (): string => apiUrlOf('/p/m/clause/1000000.html'),

  // 법적 고지
  viewLegalNotice: (): string => apiUrlOf('/p/m/clause/1000002.html'),

  /**
   * 약관 내용 보기 URL
   */
  substanceView: (substanceId: number): string =>
    apiUrlOf(`/p/m/clause-substance/${substanceId}.html`),
};

const handleClickSocialButton = (url: string) => {
  if (isBlank(url)) {
    toast.warn('준비중인 기능입니다', { position: 'top-center', autoClose: 1500 });
    return;
  }
};

type DialogParams =
  | {
      id: 'OpenSourceDialog';
      props: OpenSourceDialogProps;
    }
  | {
      id: 'IframeDialog';
      props: IframeDialogProps;
    };

export default function Footer(props: Props) {
  const { sx, className } = props;
  const isLarge = useMediaQuery(`(min-width:880px)`);

  return (
    <Box className={clsx('Footer-root', className)} sx={flatSx({ bgcolor: '#2d3033' }, sx)}>
      <Box
        sx={{
          maxWidth: 1200,
          mx: 'auto',
          px: 3,
          pt: 6,
          pb: 4,
          color: '#C6C8CA',
        }}
      >
        {isLarge ? <FooterForDesktop /> : <FooterForMobile />}
      </Box>
    </Box>
  );
}

function FooterForDesktop() {
  // 데스크탑 화면중에서 좁은 지 여부, 좁다면 주소와 사업자번호를 두줄로 표시함
  const isNarrow = useMediaQuery(`(max-width:1000px)`);
  return (
    <StackRow className="FooterForDesktop-root" spacing={6} alignItems="flex-start">
      <LogoImage />
      <Box sx={{ flex: 1 }}>
        <ClausesButtons />
        <Address
          vertical={isNarrow}
          sx={{
            mt: isNarrow ? 2 : 1,
          }}
        />
        <FlexRow.Between alignItems="center" sx={{ mt: 2 }}>
          <Copyrights />
          <SocialButtons />
        </FlexRow.Between>
      </Box>
    </StackRow>
  );
}

function FooterForMobile() {
  const isNarrow = useMediaQuery(`(max-width:600px)`);
  return (
    <Box className="FooterForMobile-root">
      <LogoImage />
      <Box sx={{ ml: '60px' }}>
        <ClausesButtons vertical={isNarrow} />
        <Address vertical sx={{ mt: 2 }} />
        <SocialButtons sx={{ mt: 2 }} />
        <Copyrights />
      </Box>
    </Box>
  );
}

function LogoImage() {
  return (
    <Box className="LogoImage-root">
      <NextImage
        src="/_static/images/ocode_logo_white.png"
        alt=""
        width={168}
        height={48}
        priority
      />
    </Box>
  );
}

function SocialButtons(props: { sx?: SxProps }) {
  const { sx } = props;
  return (
    <StackRow
      className="SocialButtons-root"
      spacing={0.5}
      sx={flatSx(
        {
          '& .MuiSvgIcon-root': {
            color: '#76787A',
          },
        },
        sx,
      )}
    >
      <IconButton onClick={() => handleClickSocialButton('')}>
        <KakaoIcon />
      </IconButton>
      <IconButton onClick={() => handleClickSocialButton('')}>
        <InstagramIcon />
      </IconButton>
      <IconButton onClick={() => handleClickSocialButton('')}>
        <GithubIcon />
      </IconButton>
      <IconButton onClick={() => handleClickSocialButton('')}>
        <NaverCafeIcon />
      </IconButton>
    </StackRow>
  );
}

function Address(props: { sx?: SxProps; vertical?: boolean }) {
  const { sx, vertical = false } = props;

  return (
    <Box
      className="Address-root"
      sx={flatSx(
        {
          fontSize: '0.8rem',
          lineHeight: 1.7,
          '& span': {
            display: 'inline-block',
          },
          '& i': {
            fontStyle: 'normal',
            whiteSpace: 'nowrap',
          },
        },

        sx,
      )}
    >
      <div>
        샘플 엔터프라이즈 주식회사, 사우스 메인 스트리트 1234번지, 플루머스 랜딩, 켄터키주 1234-1234
      </div>
      <Box
        sx={flatSx(
          !vertical && {
            display: 'flex',
            flexDirection: 'row',
            '& > * + *': {
              ml: 1,
            },
          },
          vertical && {
            display: 'flex',
            flexDirection: 'column',
            '& > * + *': {
              mt: 0.5,
            },
          },
        )}
      >
        <span>
          <i>사업자등록번호</i> <i>123-12-12345</i>
        </span>
        <span>
          <i>대표이사</i> <i>홍길동</i>
        </span>
      </Box>
    </Box>
  );
}

function Copyrights(props: { sx?: SxProps }) {
  const { sx } = props;
  return (
    <Typography
      className="Copyrights-root"
      variant="body2"
      sx={flatSx(
        {
          color: '#76787A',
        },
        sx,
      )}
    >
      Copyright©○○ Corp. All rights reserved.
    </Typography>
  );
}

function ClausesButtons(props: { sx?: SxProps; vertical?: boolean }) {
  const { sx, vertical = false } = props;
  const [dialogParams, setDialogParams] = useState<DialogParams>();

  const openOpenSourceDialog = () => {
    setDialogParams({
      id: 'OpenSourceDialog',
      props: {
        open: true,
        onClose: () => {
          setDialogParams(undefined);
        },
      },
    });
  };

  const openIframeDialog = (url: string, title?: string) => {
    setDialogParams({
      id: 'IframeDialog',
      props: {
        open: true,
        url,
        title,
        onClose: () => {
          setDialogParams(undefined);
        },
      },
    });
  };

  // 오픈 소스 클릭
  const handleClickOpenSource = (event: React.MouseEvent) => {
    event.preventDefault();
    openOpenSourceDialog();
  };

  // 개인정보 처리방침 클릭
  const handleClickClausePrivacy = (event: React.MouseEvent) => {
    event.preventDefault();
    openIframeDialog(clauseUrls.viewPrivacy(), '개인정보 처리방침');
  };

  // 이용약관 클릭
  const handleClickClauseUsage = (event: React.MouseEvent) => {
    event.preventDefault();
    openIframeDialog(clauseUrls.viewUsage(), '이용약관');
  };

  // 법적고지 클릭
  const handleClickClauseLegalNotice = (event: React.MouseEvent) => {
    event.preventDefault();
    openIframeDialog(clauseUrls.viewLegalNotice(), '법적고지');
  };

  return (
    <>
      <Box
        sx={flatSx(
          {
            position: 'relative',
            display: 'flex',
            fontSize: '0.8rem',

            // 개인정보 처리방침은 강조
            '.ClauseButton-root:first-of-type': {
              color: '#0ffA',
            },
          },
          !vertical && {
            flexDirection: 'row',
            alignItems: 'center',

            // ButtonBase의 기본 마진보다 우선순위를 높이기 위해 &&
            '&& > * + *': {
              ml: 2,
              '::before': {
                position: 'absolute',
                content: '""',
                top: 4,
                bottom: 4,
                left: -8,
                width: '1px',
                bgcolor: '#C6C8CA40',
              },
            },
          },
          vertical && {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '&& > * + *': {
              mt: 0.5,
            },
          },
          sx,
        )}
      >
        <ClauseButton onClick={handleClickClausePrivacy}>개인정보처리방침</ClauseButton>
        <ClauseButton onClick={handleClickClauseUsage}>이용약관</ClauseButton>
        <ClauseButton onClick={handleClickClauseLegalNotice}>법적고지</ClauseButton>
        <ClauseButton onClick={handleClickOpenSource}>오픈소스 라이선스</ClauseButton>
      </Box>

      {dialogParams?.id === 'IframeDialog' && <IframeDialog {...dialogParams.props} />}
      {dialogParams?.id === 'OpenSourceDialog' && <OpenSourceDialog {...dialogParams.props} />}
    </>
  );
}

function ClauseButton(props: { onClick?: MouseEventHandler; children?: ReactNode }) {
  const { onClick, children } = props;
  return (
    <ButtonBase className="ClauseButton-root" component="span" disableRipple onClick={onClick}>
      {children}
    </ButtonBase>
  );
}
