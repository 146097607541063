import type { SxProps } from '@mui/material';
import { flatSx, FlexRow } from '@ocodelib/mui';

type breedType = {
  [key: string]: string;
};

const breedTagTitle: breedType = {
  js: '자바스크립트',
  py: '파이썬',
  p5: 'P5.js',
};

const breedTagBgColor: breedType = {
  js: '#FFF7E4',
  py: '#E8F4FF',
  p5: '#FFF2F6',
};

const breedTagtextColor: breedType = {
  js: '#EDA200',
  py: '#3672A4',
  p5: '#FF6594',
};

export function GalleryListBreedTag(props: { breed: string; sx?: SxProps }) {
  const { breed, sx } = props;
  const title = breedTagTitle[breed];
  const bgColor = breedTagBgColor[breed];
  const textColor = breedTagtextColor[breed];
  return (
    <FlexRow
      inlineFlex
      alignItems="center"
      className="GalleryListBreedTag-root"
      sx={flatSx(
        {
          height: 18,
          px: 1,
          background: bgColor,
          color: textColor,
          borderRadius: '20px',
          fontSize: '0.7rem',
        },
        sx,
      )}
    >
      {title}
    </FlexRow>
  );
}
