import {
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  Favorite as FavoriteIcon,
} from '@mui/icons-material';
import { type BoxProps } from '@mui/material/Box';
import { flatSx } from '@ocodelib/mui';
import clsx from 'clsx';
import { CountWithSvgIcon } from './CountWithSvgIcon';

interface Props {
  sx?: BoxProps['sx'];
  className?: string;
  count: number | string;
  active?: boolean;
  size?: 'small' | 'medium' | 'large';
  color?: string;
}

/**
 * 숫자와 Reply icon
 */
export function CountWithHeartIcon(props: Props) {
  const { className, sx, color = '#888', active, ...restProps } = props;
  return (
    <CountWithSvgIcon
      {...restProps}
      icon={active ? <FavoriteIcon /> : <FavoriteBorderOutlinedIcon />}
      className={clsx('CountWithHeartIcon-root', className, { x_active: active })}
      sx={flatSx(
        {
          '&.x_active': {
            color: '#f00',
          },
        },
        sx,
      )}
    />
  );
}
