'use client';

import { Box, LinearProgress, Typography, type SxProps } from '@mui/material';
import type { ink, UploadedFile } from '@ocode/domain';
import { ApiRequestContext } from '@ocodelib/api-common';
import { flatSx, StackRow } from '@ocodelib/mui';
import { useIsomorphicEffect, useLoadingVisible } from '@ocodelib/mui/hooks';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { HtmlView } from '../../../components/HtmlView';
import { useApi } from '../../../provider/useApi';
import { handleError } from '../../../util/handle-error';

interface Props {
  sx?: SxProps;
  className?: string;
  kind: ink.InkKindKey;
  chapterNum?: number;
  docNum: number;
  docSn: number;
}

export function LectureContents(props: Props) {
  const { sx, className, kind, chapterNum, docNum, docSn } = props;
  const [loading, setLoading] = useState(false);
  const loadingVisible = useLoadingVisible(loading, { postponeTime: 0, minVisibleTime: 300 });
  const [doc, setDoc] = useState<ink.Doc>();
  const [attachFiles, setAttachFiles] = useState<UploadedFile[]>([]);
  const api = useApi();

  useIsomorphicEffect(() => {
    setDoc(undefined);
  }, [docSn]);

  const doLoad = useCallback(
    async (ctx: ApiRequestContext, kind: ink.InkKindKey, docSn: number) => {
      setLoading(true);
      try {
        const { doc, attachFiles = [] } = await api.inkPublished.docInfo({
          kind,
          docSn,
        });
        if (ctx.canceled) return;
        setDoc(doc);
        setAttachFiles(attachFiles);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  useEffect(() => {
    const ctx = ApiRequestContext.of();
    doLoad(ctx, kind, docSn);
    return () => {
      ctx.cancel();
    };
  }, [doLoad, kind, docSn]);

  return (
    <Box
      className={clsx('LectureContents-root', className)}
      sx={flatSx(
        {
          position: 'relative',
        },
        sx,
      )}
    >
      <StackRow alignItems="center" spacing={1} sx={{ fontWeight: 700, color: '#000' }}>
        <Typography variant="h5">
          {typeof chapterNum === 'number' ? `${chapterNum}.` : undefined}
          {docNum}.{' '}
        </Typography>
        {doc && <Typography variant="h5">{doc.title}</Typography>}
      </StackRow>
      <HtmlView html={doc?.content} />

      {loadingVisible && (
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
}
