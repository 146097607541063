'use client';

import { useIsomorphicEffect } from '@ocodelib/mui/hooks';
import { useBrowserStorageValue } from '@repo-foundation/hooks/useBrowserStorageValue';
import { useEffect } from 'react';
import { useLoginProfileLoad } from '../hooks/useLoginProfileLoad';
import { logger } from '../logger';
import { useAuthStore } from '../store/useAuthStore';
import { LoginProfileLoadedEvent, LoginProfileStorage, LoginTokenStorage } from './authentication';

const DEBUG = true;

/**
 * LoginProfileLoader는 (로컬) 스토리지로부터 인증 토큰 및 프로필 정보를 읽어서
 * zustand 스토어에 저장하는 역할을 합니다.
 *
 * LoginProfileLoader 이외의 컴포넌트들은
 * zustand 스토어에 프로필 정보가 있으면 로그인 된 것으로 판단합니다.
 *
 * 재접속 또는 새로고침시 로그인 유지를 위해 인증 토큰을 로컬 스토리지에 보관합니다.
 * 로컬 스토리지의 인증 토큰을 읽어서 profile-me api를 호출하여 프로필 정보를 로드하면
 * 로그인 된 것으로 간주할 수 있습니다.
 *
 * 프로필 정보는 서버로부터 불러오면 되므로 메모리 상에서만 보관하면 되지만,
 * 로컬 스토리지에도 보관합니다. 그 이유는 새로고침할 때, 서버와 연동하기 전에
 * 프로필 정보를 표시하기 위해서입니다.
 *
 * (기타)
 * 로컬 스토리지에 저장하는 인증 및 프로필 데이터들은 모두 AES 암호화합니다.
 */
export function LoginProfileLoader() {
  const setUserProfile = useAuthStore((s) => s.setUserProfile);
  const logout = useAuthStore((s) => s.logout);
  const loginToken = useBrowserStorageValue(LoginTokenStorage);

  // 로그인이 된 경우 주기적으로 프로필을 갱신한다
  useLoginProfileLoad(!!loginToken);

  // 초기화: 로그인 정보를 storage로부터 로드하여 zustand 스토어에 저장
  useIsomorphicEffect(() => {
    if (DEBUG) {
      logger.log('초기화: 로그인 정보를 storage로부터 로드하여 zustand 스토어에 저장');
    }
    const authToken = LoginTokenStorage.value;
    const profile = LoginProfileStorage.value;
    if (authToken && profile) {
      if (DEBUG) {
        logger.log('LoginProfileLoader: 스토리지 정보로부터 로그인', profile);
      }
      LoginProfileLoadedEvent.emit(profile);
      setUserProfile(profile);
    } else {
      if (DEBUG) {
        logger.log('LoginProfileLoader: 스토리지 정보 없음: 로그아웃 상태');
      }
      logout();
    }
    return () => {
      if (DEBUG) {
        logger.log('LoginProfileLoader: 로그인 정보 동기화 언마운트');
      }
    };
  }, [setUserProfile, logout]);

  // 로그인 토큰이 삭제되면, 강제로 로그아웃
  useIsomorphicEffect(() => {
    if (!loginToken) {
      console.log('로그인 토큰이 삭제되어 프로필 정보를 제거합니다');
      LoginProfileLoadedEvent.emit(undefined);
    }
  }, [loginToken]);

  // LoginProfileLoadedEvent 이벤트를 수신하여 zustand 스토어에 저장
  useEffect(() => {
    const dispose = LoginProfileLoadedEvent.on((value) => {
      setUserProfile(value);
      LoginProfileStorage.value = value;
    });
    return () => {
      dispose();
    };
  }, [setUserProfile]);

  return null;
}
