'use client';

import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent, Divider, IconButton, TextField, Typography } from '@mui/material';
import { type CoppiaPub } from '@ocode/domain';
import {
  CooldownButton,
  FlexColumn,
  FlexRow,
  isEnterKeyEvent,
  isEnterOrTabKeyEvent,
  requestFocusSelector,
  StackColumn,
} from '@ocodelib/mui';
import { isBlank, isNotBlank, trim } from '@ocodelib/util/string';
import { PasswordTextField } from '@repo-foundation/components/PasswordTextField';
import { useLocale } from 'next-intl';
import NextImage from 'next/image';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { LoginProfileLoadedEvent } from '../../../auth/authentication';
import { useCoppiaPub } from '../../../provider/CoppiaPubProvider';
import { useApi } from '../../../provider/useApi';
import { routerPushI18n } from '../../../router';
import { handleError } from '../../../util/handle-error';

interface InputData {
  loginId: string;
  pw: string;
}

interface Props {
  onClose: React.MouseEventHandler;
  onLoginSuccess: () => void;
}

export function LoginForm(props: Props) {
  const { onClose, onLoginSuccess } = props;
  const locale = useLocale();
  const rootRef = useRef<HTMLElement>(null);
  const api = useApi();
  const coppia = useCoppiaPub();
  const [inputData, setInputData] = useState<Partial<InputData>>({});
  const [loading, setLoading] = useState(false);
  const [loginProcessing, setLoginProcessing] = useState(false);

  const updateInput = (part: Partial<InputData>) => {
    setInputData((prev) => ({ ...prev, ...part }));
  };

  const focus = useCallback((selector: string) => {
    requestFocusSelector(rootRef.current, selector, 0);
  }, []);

  /**
   * 서버 로그인 실행
   */
  const doLogin = useCallback(
    async (loginId: string, password: string, coppia: CoppiaPub): Promise<boolean> => {
      setLoading(true);
      try {
        // const publicKey = await CoppiaUtil.loadPublicKey(coppia.pubKey);
        // 임시로 비번 암호안함
        // const pw = await CoppiaUtil.encryptText(publicKey, password);
        // const cid = coppia.cid,
        const { profile: userProfile } = await api.auth.signIn({
          cid: 0,
          loginId,
          pw: password,
        });
        LoginProfileLoadedEvent.emit(userProfile);
        toast.success('로그인 되었습니다', {
          autoClose: 1500,
          position: 'top-center',
        });
        return true;
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
      return false;
    },
    [api],
  );

  const validateAndLogin = () => {
    // if (!coppia) {
    //   console.warn("coppia is null");
    //   return;
    // }

    setLoginProcessing(true);
    const loginId = trim(inputData.loginId);
    const pw = trim(inputData.pw);
    if (isBlank(loginId)) {
      toast.warn('사용자 ID를 입력해주세요');
      setLoginProcessing(false);
      return false;
    }

    if (isBlank(pw)) {
      toast.warn('비밀번호를 입력해주세요');
      setLoginProcessing(false);
      return;
    }

    doLogin(loginId, pw, coppia as any)
      .then((succ) => {
        if (succ) {
          onLoginSuccess();
        }
      })
      .finally(() => {
        setLoginProcessing(false);
      });
  };

  // 로그인 버튼 클릭
  const handleClickLogin = () => {
    if (loginProcessing) return;
    validateAndLogin();
  };

  // 비밀번호 찾기 버튼 클릭
  const handleClickPwFind = () => {
    //
  };

  // 가입 버튼 클릭
  const handleClickJoin = () => {
    routerPushI18n('/join');
  };

  const { loginId, pw } = inputData;
  return (
    <DialogContent
      className="LoginForm-root"
      ref={rootRef}
      sx={{ pb: 7, px: 5, position: 'relative' }}
    >
      <Box sx={{ position: 'relative', pt: 5 }}>
        <IconButton
          sx={{ position: 'absolute', top: -8, right: -30 }}
          size="small"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <LoginTitle />
          <Typography
            sx={{
              mt: 1,
              fontSize: '0.8rem',
              fontWeight: 500,
              color: '#4E5052',
            }}
          >
            회원 서비스 이용을 위해 로그인해 주세요.
          </Typography>
        </Box>
        <StackColumn sx={{ mt: 5 }} spacing={3}>
          <TextField
            fullWidth
            label="아이디 입력"
            variant="outlined"
            disabled={loading || loginProcessing}
            size="medium"
            onChange={(e) => {
              updateInput({ loginId: e.target.value });
            }}
            value={loginId ?? ''}
            className="LoginForm-loginInput LoginForm-loginId"
            onKeyDown={(e) => {
              if (isNotBlank(loginId) && isEnterOrTabKeyEvent(e)) {
                e.preventDefault();
                focus('.LoginForm-pw input');
              }
            }}
          />
          <PasswordTextField
            className="LoginForm-loginInput LoginForm-pw"
            label="비밀번호 입력"
            value={pw ?? ''}
            onChange={(e) => {
              updateInput({ pw: e.target.value });
            }}
            onKeyDown={(e) => {
              if (isNotBlank(pw) && isEnterKeyEvent(e)) {
                e.preventDefault();
                focus('.LoginForm-loginBtn');
                handleClickLogin();
              }
            }}
          />
        </StackColumn>

        <FlexColumn alignItems="center" sx={{ mt: 1 }}>
          <CooldownButton
            className="LoginForm-loginBtn"
            variant="contained"
            size="large"
            color="primary"
            disabled={loading || loginProcessing}
            onClick={handleClickLogin}
            sx={{
              mt: 3,
              width: 150,
            }}
          >
            로그인
          </CooldownButton>
          <FlexRow
            alignItems="center"
            sx={{
              mt: 1,
              color: '#363636',
              flexShrink: 0,
              '& .MuiDivider-root': {
                height: 12,
                alignSelf: 'center',
                my: 0,
                mx: 1,
              },
            }}
          >
            <CooldownButton disabled={loading} onClick={handleClickPwFind} style={{ opacity: 0.7 }}>
              비밀번호 찾기
            </CooldownButton>
            <Divider orientation="vertical" flexItem />
            <CooldownButton disabled={loading} onClick={handleClickJoin} style={{ opacity: 0.7 }}>
              회원가입
            </CooldownButton>
          </FlexRow>
        </FlexColumn>
      </Box>
    </DialogContent>
  );
}

function LoginTitle() {
  return (
    <Typography
      component="div"
      sx={{
        position: 'relative',
        display: 'inline-block',
        fontSize: '1.8rem',
        lineHeight: 1,
        fontWeight: 800,
        '& img': {
          position: 'absolute',
          top: 0,
          right: -24,
        },
      }}
    >
      Login
      <NextImage src="/_static/images/login_dolphin.png" width={24} height={24} alt="" />
    </Typography>
  );
}
