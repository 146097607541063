import type { SxProps } from '@mui/material';
import { ButtonBase, Typography } from '@mui/material';
import { flatSx, StackRow } from '@ocodelib/mui';
import { LinkI18n } from '@repo-ui/i18n/routing';
import HomeTopMenuFaqIcon from '@repo-ui/icons/HomeTopMenuFaqIcon';
import HomeTopMenuGalleryIcon from '@repo-ui/icons/HomeTopMenuGalleryIcon';
import HomeTopMenuMyCodingIcon from '@repo-ui/icons/HomeTopMenuMyCodingIcon';
import clsx from 'clsx';

interface Props {
  sx?: SxProps;
  className?: string;
}

const HOME_BTN_DATA = [
  { icon: <HomeTopMenuGalleryIcon />, title: '갤러리', href: '/gallery/list' },
  {
    icon: <HomeTopMenuMyCodingIcon />,
    title: '나의 코딩',
    href: '/coding/mine',
  },
  { icon: <HomeTopMenuFaqIcon />, title: 'FAQ', href: '/faq' },
];

export default function HomeSubMenu(props: Props) {
  const { sx, className } = props;
  return (
    <StackRow
      className={clsx('HomeSubMenu-root', className)}
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      sx={flatSx(
        {
          maxWidth: '100%',
        },
        sx,
      )}
    >
      {HOME_BTN_DATA.map((it) => (
        <ButtonBase
          key={it.title}
          LinkComponent={LinkI18n}
          href={it.href}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            justifyContent: { xs: 'flex-end', md: 'space-between' },
            alignItems: 'center',
            width: 267,
            height: '100%',
            mt: { xs: 1, md: 0 },
            py: 2,
            px: {
              xs: 1,
              md: 4,
            },
            background: '#f3f5f8',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              textAlign: 'center',
              mt: { xs: 2, md: 0 },
            }}
          >
            {it.title}
          </Typography>
          {it.icon}
        </ButtonBase>
      ))}
    </StackRow>
  );
}
