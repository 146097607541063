import { defineRouting } from 'next-intl/routing';
import { createNavigation } from 'next-intl/navigation';

export const routing = defineRouting({
  // A list of all locales that are supported
  locales: ['en', 'ko'],

  // Used when no locale matches
  defaultLocale: 'en',
});

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const {
  //
  Link: LinkI18n,
  redirect: redirectI18n,
  usePathname: usePathnameI18n,
  useRouter: useRouterI18n,
  getPathname: getPathnameI18n,
} = createNavigation(routing);
