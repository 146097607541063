'use client';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import type { SxProps } from '@mui/material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { CopyButtonWrapper, flatSx } from '@ocodelib/mui';
import clsx from 'clsx';

interface Props {
  sx?: SxProps;
  className?: string;
  text: string | undefined | null | (() => string | undefined | null);
}

export function ClipboardButton(props: Props) {
  const { sx, className, text } = props;

  return (
    <CopyButtonWrapper timeout={700} value={text}>
      {({ copied, copy }) => (
        <Box
          className={clsx('ClipboardButton-root', className)}
          sx={flatSx(
            {
              position: 'relative',
              display: 'inline-block',
            },
            sx,
          )}
        >
          <Tooltip title="copy to clipboard">
            <IconButton
              size="small"
              onClick={() => {
                copy();
              }}
              disabled={copied}
              sx={{
                opacity: copied ? 0 : 1,
              }}
            >
              <ContentCopyIcon style={{ fontSize: '1rem' }} />
            </IconButton>
          </Tooltip>
          {copied && (
            <span
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 'auto',
                fontSize: '0.6rem',
                color: '#999',
                transform: 'rotate(-45deg)',
              }}
            >
              Copied
            </span>
          )}
        </Box>
      )}
    </CopyButtonWrapper>
  );
}
