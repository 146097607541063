'use client';

import CloseIcon from '@mui/icons-material/Close';
import type { SxProps } from '@mui/material';
import { Box, IconButton, Typography } from '@mui/material';
import { flatSx } from '@ocodelib/mui';
import clsx from 'clsx';
import type { CSSProperties, ReactNode } from 'react';
import { forwardRef } from 'react';

interface Props {
  sx?: SxProps;
  style?: CSSProperties;
  title?: ReactNode;
  onClose?: () => void;
  size?: 'small' | 'medium';
  children?: ReactNode;
}

export const CustomDialogTitle = forwardRef<HTMLElement, Props>((props, ref) => {
  const { sx, title, style, size = 'medium', onClose, children } = props;

  return (
    <Box
      ref={ref}
      className={clsx('CustomDialogTitle-root', {
        'CustomDialogTitle-medium': size === 'medium',
        'CustomDialogTitle-small': size === 'small',
      })}
      style={style}
      sx={flatSx(
        {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 1,
          pl: {
            xs: 2,
            md: 3,
          },
          '&.CustomDialogTitle-medium': {
            minHeight: 52,
          },
          '&.CustomDialogTitle-small': {
            minHeight: 48,
          },
        },
        sx,
      )}
    >
      {title && (
        <Box display="flex" alignItems="center">
          {typeof title === 'string' ? (
            <Typography
              variant={size === 'small' ? 'h6' : 'h5'}
              component="div"
              sx={{ color: 'inherit' }}
            >
              {title}
            </Typography>
          ) : (
            title
          )}
        </Box>
      )}

      {children}
      {onClose && (
        <IconButton onClick={onClose} size={size} sx={{ ml: 3 }} color="inherit">
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
});

CustomDialogTitle.displayName = 'CustomDialogTitle';
