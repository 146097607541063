import {
  BookmarkBorder as BookmarkBorderIcon,
  Bookmark as BookmarkIcon,
} from '@mui/icons-material';
import { type BoxProps } from '@mui/material/Box';
import { flatSx } from '@ocodelib/mui';
import clsx from 'clsx';
import { CountWithSvgIcon } from './CountWithSvgIcon';

interface Props {
  sx?: BoxProps['sx'];
  className?: string;
  count: number | string;
  active?: boolean;
  size?: 'small' | 'medium' | 'large';
  color?: string;
}

/**
 * 숫자와 Bookmark icon
 */
export function CountWithBookmarkIcon(props: Props) {
  const { className, sx, color = '#888', active, ...restProps } = props;
  return (
    <CountWithSvgIcon
      {...restProps}
      icon={active ? <BookmarkIcon /> : <BookmarkBorderIcon />}
      className={clsx('CountWithBookmarkIcon-root', className, { x_active: active })}
      sx={flatSx(
        {
          '&.x_active': {
            color: '#00f',
          },
        },
        sx,
      )}
    />
  );
}
