import { createSvgIcon } from '@mui/material';

const HomeSttIcon = createSvgIcon(
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52 30V34C52 34.5304 51.7893 35.0391 51.4142 35.4142C51.0391 35.7893 50.5304 36 50 36C49.4696 36 48.9609 35.7893 48.5858 35.4142C48.2107 35.0391 48 34.5304 48 34V32H42V48H44C44.5304 48 45.0391 48.2107 45.4142 48.5858C45.7893 48.9609 46 49.4696 46 50C46 50.5304 45.7893 51.0391 45.4142 51.4142C45.0391 51.7893 44.5304 52 44 52H36C35.4696 52 34.9609 51.7893 34.5858 51.4142C34.2107 51.0391 34 50.5304 34 50C34 49.4696 34.2107 48.9609 34.5858 48.5858C34.9609 48.2107 35.4696 48 36 48H38V32H32V34C32 34.5304 31.7893 35.0391 31.4142 35.4142C31.0391 35.7893 30.5304 36 30 36C29.4696 36 28.9609 35.7893 28.5858 35.4142C28.2107 35.0391 28 34.5304 28 34V30C28 29.4696 28.2107 28.9609 28.5858 28.5858C28.9609 28.2107 29.4696 28 30 28H50C50.5304 28 51.0391 28.2107 51.4142 28.5858C51.7893 28.9609 52 29.4696 52 30Z"
      fill="#C862DA"
    />
    <g opacity="0.75">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.65252 17.6272C5.56369 17.4994 5.48594 17.3832 5.41984 17.2817H3.0002C2.50623 17.2817 2.0325 17.0855 1.68321 16.7362C1.33392 16.3869 1.1377 15.9132 1.1377 15.4192V8.50422C1.1377 8.01025 1.33392 7.53652 1.68321 7.18723C2.0325 6.83795 2.50623 6.64172 3.0002 6.64172H5.42406C5.4901 6.54205 5.56772 6.42794 5.65636 6.30252C5.97266 5.85497 6.43479 5.25547 7.01702 4.65257C8.14908 3.48031 9.88824 2.13672 12.0002 2.13672C12.4765 2.13672 12.8627 2.52287 12.8627 2.99922V20.9992C12.8627 21.4756 12.4765 21.8617 12.0002 21.8617C9.8826 21.8617 8.14211 20.4928 7.0121 19.3032C6.43026 18.6907 5.9685 18.0817 5.65252 17.6272ZM15.3578 6.92402C15.6754 6.56908 16.2207 6.53886 16.5757 6.85654C16.9329 7.1763 17.2557 7.53262 17.5387 7.91966L17.5394 7.92069C18.403 9.10539 18.8665 10.5344 18.8629 12.0004C18.8627 13.5126 18.38 14.9146 17.5607 16.0492L17.5602 16.0498C17.2721 16.4481 16.9419 16.8143 16.5755 17.1421C16.2204 17.4597 15.6752 17.4293 15.3576 17.0742C15.04 16.7192 15.0704 16.1739 15.4254 15.8564C15.6997 15.6111 15.9468 15.337 16.1625 15.0389C16.7748 14.1909 17.1379 13.1404 17.1379 11.9992V11.997C17.1408 10.8975 16.7933 9.8258 16.1458 8.93726C15.934 8.64771 15.6925 8.38114 15.4252 8.14191C15.0703 7.82424 15.0401 7.27897 15.3578 6.92402ZM17.7075 2.75509C17.3024 2.5045 16.7708 2.62976 16.5202 3.03487C16.2697 3.43997 16.3949 3.97152 16.8 4.22211C19.4051 5.83353 21.1377 8.71376 21.1377 11.9986C21.1377 15.3351 19.3502 18.2552 16.6762 19.851C16.2672 20.0951 16.1335 20.6246 16.3776 21.0336C16.6217 21.4426 17.1512 21.5763 17.5602 21.3322C20.7343 19.438 22.8627 15.9671 22.8627 11.9986C22.8627 8.09143 20.7994 4.66766 17.7075 2.75509Z"
        fill="#039BE5"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6848 20.182L38.1783 16.6754C37.7877 16.2849 37.7877 15.6517 38.1783 15.2612C38.5688 14.8707 39.202 14.8707 39.5925 15.2612L41.9626 17.6313C42.6733 14.3318 41.2004 10.8677 38.1812 9.08067C36.9307 8.34192 35.5026 7.95738 34.0502 7.96849C32.5978 7.97959 31.1758 8.38586 29.9367 9.14371C29.4656 9.43187 28.85 9.28354 28.5618 8.81239C28.2737 8.34124 28.422 7.7257 28.8932 7.43753C30.442 6.49024 32.2194 5.98243 34.0349 5.96855C35.8504 5.95466 37.636 6.4356 39.1991 7.35909C42.747 9.45856 44.5963 13.4337 44.0431 17.3522L46.1341 15.2612C46.5246 14.8707 47.1578 14.8707 47.5483 15.2612C47.9388 15.6517 47.9388 16.2849 47.5483 16.6754L43.5704 20.6533C43.1799 21.0438 42.5467 21.0438 42.1562 20.6533L41.7247 20.2218C41.711 20.2089 41.6977 20.1956 41.6848 20.182Z"
      fill="url(#paint0_linear_2178_29766)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2178_29766"
        x1="28.915"
        y1="-3.08594"
        x2="40.6301"
        y2="19.8373"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#039BE5" />
        <stop offset="1" stopColor="#C862DA" />
      </linearGradient>
    </defs>
  </svg>,
  'HomeSttIcon',
);

export default HomeSttIcon;
