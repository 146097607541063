'use client';

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Box, IconButton, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { Center, FlexRow } from '@ocodelib/mui';
import { useDebounceEffect } from '@ocodelib/mui/hooks';
import clsx from 'clsx';
import { useLocale } from 'next-intl';
import NextImage from 'next/image';
import React, { useRef, useState } from 'react';
import { LocaleSwitcher } from '../../../../components/LocaleSwitcher';
import { LoginButton } from '../../../../components/LoginButton';
import { LinkI18n, usePathnameI18n } from '../../../../i18n/routing';
import { mainLayoutConstants as LAYOUT } from '../../main-layout-constants';
import { Submenu } from './Submenu';
import { TopMenu } from './TopMenu';

interface AppBarProps extends MuiAppBarProps {
  shift: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'shift',
})<AppBarProps>(({ theme, shift }) => ({
  zIndex: theme.zIndex.drawer + (shift ? 1 : -1),
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: '#fff',
  color: '#FFF',
  ...(shift && {
    width: `calc(100% - ${LAYOUT.sidemenu.width}px)`,
    marginLeft: LAYOUT.sidemenu.width,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!shift && {
    '& .MuiToolbar-root': {
      paddingLeft: theme.spacing(3.5),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(3),
      },
    },
  }),
}));

interface Props {
  className?: string;
  isSidebarOpen: boolean;
  hideLoginButton?: boolean;
  isMobile: boolean;
  elevation?: number;
  onClickMenuButton?: React.MouseEventHandler;
}

export default function MainLayoutTopbar(props: Props) {
  const {
    className,
    isMobile,
    isSidebarOpen,
    hideLoginButton = false,
    elevation = 0,
    onClickMenuButton,
  } = props;
  const locale = useLocale();
  const pathname = usePathnameI18n();
  const rootRef = useRef<HTMLElement | null>(null);
  const theme = useTheme();
  const lgOrDown = useMediaQuery(theme.breakpoints.down('lg'));
  const shift = Boolean(isSidebarOpen && !lgOrDown);
  const [activeMenuIndex, setActiveMenuIndex] = useState<number>(-1);
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [debouncedSubmenuVisible, setDebouncedSubmenuVisible] = useState(false);
  const [menuLeft, setMenuLeft] = useState(0);

  useDebounceEffect(
    () => {
      setDebouncedSubmenuVisible(submenuVisible);
    },
    [submenuVisible],
    { wait: 100 },
  );

  const onChangeMenuMeasure = () => {
    const root = rootRef.current;
    if (!root) return;
    const topMenuBox = root.querySelector('.TopMenu-menuBox') as HTMLElement;
    if (!topMenuBox) {
      console.warn('topMenuBox not exists');
      return;
    }
    setMenuLeft(calcAbsOffset(topMenuBox).x + -220);
  };

  const fixSubmenuLeft = () => {
    const root = rootRef.current;
    if (!root) return;
    const topMenuBox = root.querySelector('.TopMenu-menuBox') as HTMLElement;
    const submenuBox = root.querySelector('.Submenu-root .Submenu-menuBox') as HTMLElement;
    if (!topMenuBox) {
      console.warn('topMenuBox not exists');
      return;
    }
    if (!submenuBox) {
      console.warn('submenuBox not exists');
      return;
    }
    // console.log("XXXX submenu left=", calcAbsOffset(topMenuBox).x);
    submenuBox.style.left = calcAbsOffset(topMenuBox).x + '-220px';
  };

  // 서브메뉴 표시/감추기 - 표시할때는 즉시 표시, 감출때는 200ms후에 감추기
  const showSubmenu = (visible: boolean) => {
    if (visible) {
      setSubmenuVisible(true);
      setDebouncedSubmenuVisible(true);
    } else {
      setSubmenuVisible(false);
    }
  };

  const handleMenuMouseEnter = (event: React.MouseEvent, menuIndex: number) => {
    setActiveMenuIndex(menuIndex);
    showSubmenu(true);
  };

  const handleMenuMouseLeave = (event: React.MouseEvent) => {
    showSubmenu(false);
  };

  const handleSubmenuMouseEnter = (event: React.MouseEvent) => {
    fixSubmenuLeft();
    showSubmenu(true);
  };

  const handleSubmenuMouseLeave = (event: React.MouseEvent) => {
    showSubmenu(false);
    // showSubmenu(true);
  };

  return (
    <AppBar
      ref={rootRef}
      className={clsx('MainLayoutTopbar-root', className)}
      position="fixed"
      shift={shift}
      elevation={1}
      sx={{
        background: '#26282A',
        color: '#222',
        boxShadow: 'none',
        ...(elevation > 0 && {
          boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 12px 0px',
        }),
        px: { xs: '0px', md: '36px' },
        '& .MuiToolbar-root': {
          px: 0,
        },
      }}
    >
      <Toolbar sx={{ height: LAYOUT.appbarHeight }}>
        <Center sx={{ width: '100%', flexGrow: 1 }}>
          {isMobile ? (
            <NavForMobile isSidebarOpen={isSidebarOpen} onClickMenuButton={onClickMenuButton} />
          ) : (
            <NavForDesktop
              activeMenuIndex={activeMenuIndex}
              submenuVisible={submenuVisible}
              hideLoginButton={hideLoginButton}
              onMenuMouseEnter={handleMenuMouseEnter}
              onMenuMouseLeave={handleMenuMouseLeave}
              onChangeMeasure={onChangeMenuMeasure}
            />
          )}
        </Center>
      </Toolbar>

      {!isMobile && debouncedSubmenuVisible && (
        <Submenu
          pathname={pathname}
          onMouseEnter={handleSubmenuMouseEnter}
          onMouseLeave={handleSubmenuMouseLeave}
          menuLeft={menuLeft}
          locale={locale}
        />
      )}
    </AppBar>
  );
}

function calcAbsOffset(element: HTMLElement): { x: number; y: number } {
  let el: HTMLElement | null = element;
  let x = 0;
  let y = 0;
  do {
    x += el.offsetLeft;
    y += el.offsetTop;
    el = el.offsetParent as HTMLElement | null;
  } while (el);
  return { x, y };
}

function NavForDesktop(props: {
  activeMenuIndex: number;
  hideLoginButton?: boolean;
  onMenuMouseEnter: (event: React.MouseEvent, menuIndex: number) => void;
  onMenuMouseLeave: React.MouseEventHandler;
  onChangeMeasure: VoidFunction;
  submenuVisible: boolean;
}) {
  const {
    activeMenuIndex,
    hideLoginButton = false,
    submenuVisible,
    onMenuMouseEnter,
    onMenuMouseLeave,
    onChangeMeasure,
  } = props;
  const pathname = usePathnameI18n();

  return (
    <Center sx={{ width: '100%', px: 25 }}>
      <TopMenu
        className="MainLayoutTopbar-topMenu"
        activeMenuIndex={activeMenuIndex}
        visibleIndicator={submenuVisible}
        pathname={pathname}
        onMenuMouseEnter={onMenuMouseEnter}
        onMenuMouseLeave={onMenuMouseLeave}
        onChangeMeasure={onChangeMeasure}
      />
      <FlexRow.Between
        alignItems="center"
        className="MainLayoutTopbar-toolBox"
        sx={{
          '& .MuiSvgIcon-root': {
            color: '#76787A',
          },
        }}
      >
        {!hideLoginButton && <LoginButton />}
        <LocaleSwitcher
          sx={{
            '& .MuiSvgIcon-root': {
              color: '#76787A',
            },
          }}
        />
      </FlexRow.Between>
    </Center>
  );
}

function NavForMobile(props: {
  isSidebarOpen: boolean;
  onClickMenuButton?: React.MouseEventHandler;
}) {
  const { isSidebarOpen, onClickMenuButton } = props;

  return (
    <FlexRow.Between alignItems="center" sx={{ width: '100%', pl: 1 }}>
      <FlexRow>
        <IconButton
          onClick={onClickMenuButton}
          aria-label="open drawer"
          sx={{
            color: '#fff',
          }}
        >
          {isSidebarOpen ? <MenuOpenIcon htmlColor="#fff" /> : <MenuIcon htmlColor="#fff" />}
        </IconButton>
        <Box
          className="TopMenu-logo"
          sx={{
            ml: 2,
            mt: 1,
            '& a': {
              width: 174,
            },
          }}
        >
          <LinkI18n href="/" aria-label="move to home">
            <NextImage
              src="/_static/images/ocode_logo_white.png"
              alt=""
              width={168}
              height={52}
              priority
            />
          </LinkI18n>
        </Box>
      </FlexRow>
      <Box
        className="MainLayoutTopbar-toolBox"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          mr: 1,
        }}
      >
        <LocaleSwitcher />
      </Box>
    </FlexRow.Between>
  );
}
