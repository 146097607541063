import { Box } from '@mui/material';
import HomeTopIntro from './components/HomeTopIntro/HomeTopIntro';
import HomeAiTrainIntro from './components/HomeAiTrainIntro/HomeAiTrainIntro';
import HomeAiTechIntro from './components/HomeAiTechIntro/HomeAiTechIntro';
import HomeCodingIntro from './components/HomeCodingIntro/HomeCodingIntro';

export function HomeMain() {
  return (
    <Box className="HomeMain-root">
      <HomeTopIntro />
      <HomeAiTrainIntro />
      <HomeAiTechIntro />
      <HomeCodingIntro />
    </Box>
  );
}
