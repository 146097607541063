import type { PaletteColor } from '@mui/material';
import {
  amber,
  blue,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import type {
  CustomPaletteName,
  PaletteColorInfo,
} from '@repo-foundation/util/mui-theme-generate-colors';
import {
  createColorVarsByColorInfo,
  generateColorOptions,
} from '@repo-foundation/util/mui-theme-generate-colors';

export const CUSTOM_PALETTES = {
  red: {
    contrastText: '#fff',
    light: red[400],
    main: red[600],
    dark: red[800],
  },
  pink: {
    contrastText: '#fff',
    light: pink[400],
    main: pink[500],
    dark: pink[700],
  },
  purple: {
    contrastText: '#fff',
    light: purple[300],
    main: purple[500],
    dark: purple[700],
  },
  deepPurple: {
    contrastText: '#fff',
    light: deepPurple[400],
    main: deepPurple[500],
    dark: deepPurple[700],
  },
  indigo: {
    contrastText: '#fff',
    light: indigo[400],
    main: indigo[500],
    dark: indigo[700],
  },
  blue: {
    contrastText: '#fff',
    light: blue[400],
    main: blue[700],
    dark: blue[800],
  },
  lightBlue: {
    contrastText: '#fff',
    light: lightBlue[500],
    main: lightBlue[700],
    dark: lightBlue[900],
  },
  cyan: {
    contrastText: '#fff',
    light: cyan[600],
    main: cyan[700],
    dark: cyan[800],
  },
  teal: {
    contrastText: '#fff',
    light: teal[400],
    main: teal[500],
    dark: teal[700],
  },
  green: {
    contrastText: '#fff',
    light: green[500],
    main: green[800],
    dark: green[900],
  },
  lightGreen: {
    contrastText: '#fff',
    light: lightGreen[500],
    main: lightGreen[700],
    dark: lightGreen[900],
  },
  lime: {
    contrastText: '#000',
    light: lime[400],
    main: lime[500],
    dark: lime[700],
  },
  yellow: {
    contrastText: '#000',
    light: yellow[400],
    main: yellow[600],
    dark: yellow[800],
  },
  amber: {
    contrastText: '#000',
    light: amber[400],
    main: amber[600],
    dark: amber[800],
  },
  orange: {
    contrastText: '#fff',
    light: orange[500],
    main: orange[800],
    dark: orange[900],
  },
  deepOrange: {
    contrastText: '#fff',
    light: deepOrange[400],
    main: deepOrange[600],
    dark: deepOrange[800],
  },
  brown: {
    contrastText: '#fff',
    light: brown[400],
    main: brown[600],
    dark: brown[800],
  },
};

export type CustomColorKey = keyof typeof CUSTOM_PALETTES;

const colorInfoCache_: Record<string, PaletteColorInfo> = {};

function getOrCreateColorInfo_(colorKey: string): PaletteColorInfo {
  let value = colorInfoCache_[colorKey];
  if (value) {
    return value;
  }

  const colorOptions: PaletteColor = CUSTOM_PALETTES[colorKey] || CUSTOM_PALETTES.red;
  value = generateColorOptions(colorOptions);
  colorInfoCache_[colorKey] = value;
  return value;
}

function cssVarGenerator_(colorKey: string) {
  return function generate(
    paletteName: string | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  ) {
    const colorInfo = getOrCreateColorInfo_(colorKey);
    return createColorVarsByColorInfo(paletteName, colorInfo);
  };
}

const paletteCssFunctions = {} as Record<
  keyof typeof CUSTOM_PALETTES,
  (paletteNm: string) => Record<string, string>
>;

Object.keys(CUSTOM_PALETTES).forEach((colorKey) => {
  Object.defineProperty(paletteCssFunctions, colorKey, {
    value: cssVarGenerator_(colorKey),
  });
});

// example
// CUSTOM_PALETTE_CSS.pink('primary') =>
//        {
//          '--mui-palette-primary-main':'#xxxx',
//          '--mui-palette-primary-light':'#xxxx',
//          '--mui-palette-primary-dark':'#xxxx',
//          '--mui-palette-primary-contrastText':'',
//          '--mui-palette-primary-mainChannel':'',
//          '--mui-palette-primary-lightChannel':'',
//          '--mui-palette-primary-darkChannel':'',
//          '--mui-palette-primary-contrastTextChannel':'',
//        }

export function customColorCssVars(
  colorKey: CustomColorKey,
  paletteName: CustomPaletteName,
): Record<string, string> | undefined {
  return paletteCssFunctions[colorKey]?.(paletteName);
}

export const P5_PALETTE = {
  light: '#ef9a9a',
  main: '#EF5350',
  dark: '#d32e2f',
  contrastText: '#fff',
};

// export const JS_PALETTE = {
//   light: '#d7cb4b',
//   main: '#d3a12f',
//   dark: '#ca7d24',
//   contrastText: '#000',
// };
export const JS_PALETTE = {
  light: '#dee168',
  main: '#d7cb4b',
  dark: '#d1a036',
  contrastText: '#000',
};

export const PY_PALETTE = {
  light: '#23b6ff',
  main: '#0c88d9',
  dark: '#0b76c5',
  contrastText: '#fff',
};
