'use client';

import { Box } from '@mui/material';
import { useIsomorphicEffect } from '@ocodelib/mui/hooks';
import { useBrowserStorageValue } from '@repo-foundation/hooks/useBrowserStorageValue';
import { createColorVariables } from '@repo-foundation/util/mui-theme-generate-colors';
import type { CSSProperties, ReactNode } from 'react';
import { useState } from 'react';
import type { DynamicThemePayload } from '../user-preference/theme-preference';
import { DynamicThemePreference } from '../user-preference/theme-preference';

interface ThemeSwitcherProps {
  children?: ReactNode;
}

export function DynamicThemeWrapper(props: ThemeSwitcherProps) {
  const { children } = props;
  const [styles, setStyles] = useState<CSSProperties>();
  const themePayload = useBrowserStorageValue(DynamicThemePreference);

  useIsomorphicEffect(() => {
    if (themePayload) {
      setStyles(generateCssVarsStyle(themePayload));
    } else {
      setStyles(undefined);
    }
  }, [themePayload]);

  return styles ? (
    <Box component="div" style={styles}>
      {children}
    </Box>
  ) : (
    <>{children}</>
  );
}

function generateCssVarsStyle(payload: DynamicThemePayload) {
  const { palette } = payload;
  if (!palette) return undefined;

  const result = {} as Record<string, string>;
  const paletteImpl = palette;

  let cnt = 0;

  function addCssVars_(
    paletteName: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  ) {
    const options = paletteImpl[paletteName];
    if (!options) return;
    Object.entries(createColorVariables(paletteName, options)).forEach(([cssVar, value]) => {
      result[cssVar] = value;
    });
    cnt++;
  }

  ['primary', 'secondary', 'error', 'info', 'success', 'warning'].forEach((it) => {
    addCssVars_(it as any);
  });

  return cnt > 0 ? result : undefined;
}
