import Box, { type BoxProps } from '@mui/material/Box';
import { Center, flatSx } from '@ocodelib/mui';
import clsx from 'clsx';

interface Props {
  sx?: BoxProps['sx'];
  className?: string;
  color?: string;
  icon: React.ReactNode;
  count: number | string;
  size?: 'small' | 'medium' | 'large';
}

export function CountWithSvgIcon(props: Props) {
  const { sx, className, icon, color = '#888', count, size = 'medium' } = props;
  return (
    <Center
      className={clsx('CountWithSvgIcon-root', className, {
        x_small: size === 'small',
        x_medium: size === 'medium',
        x_large: size === 'large',
      })}
      sx={flatSx(
        {
          color,
          '& .CountWithSvgIcon-count': {
            color,
            fontSize: '0.7rem',
            lineHeight: 1,
            whiteSpace: 'none',
            ml: '2px',
          },

          '&.x_small svg': {
            width: 18,
            height: 18,
          },
          '&.x_medium svg': {
            width: 20,
            height: 20,
          },
          '&.x_large svg': {
            width: 24,
            height: 24,
          },
          '&.x_large .CountWithSvgIcon-count': {
            fontSize: '0.9rem',
            ml: '4px',
          },
        },
        sx,
      )}
    >
      {icon}
      <Box className="CountWithSvgIcon-count">{count}</Box>
    </Center>
  );
}
