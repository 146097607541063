import HomeKwsIcon from '@repo-ui/icons/HomeKwsIcon';
import HomeSttIcon from '@repo-ui/icons/HomeSttIcon';
import HomeTtsIcon from '@repo-ui/icons/HomeTtsIcon';

export const AI_BUTTON_DATA = [
  {
    icon: <HomeTtsIcon />,
    title1: '음성합성',
    title2: '(TTS)',
    subtitle: 'Text to Speech',
    textColor: '#fff',
    bgcolor: 'rgba(0, 0, 0, 0.25)',
    action: 'tts',
  },
  {
    icon: <HomeSttIcon />,
    title1: '음성인식',
    title2: '(STT)',
    subtitle: 'Speech to Text',
    textColor: '#fff',
    bgcolor: 'rgba(0, 0, 0, 0.25)',
    action: 'stt',
  },
  {
    icon: <HomeKwsIcon />,
    title1: '호출어',
    title2: '(KWS)',
    subtitle: 'Keyword Spotting',
    textColor: '#fff',
    bgcolor: 'rgba(0, 0, 0, 0.25)',
    action: 'kws',
  },
];
