'use client';

import { useIsomorphicEffect } from '@ocodelib/mui/hooks';
import { routerI18nEvents } from '../router/index';
import { useRouterI18n } from '../i18n/routing';

export function useRouterI18nEvent() {
  const router = useRouterI18n();

  useIsomorphicEffect(() => {
    const onRouterPush = (link: string) => {
      router.push(link);
    };

    const onRouterBack = () => {
      router.back();
    };

    routerI18nEvents.on('push', onRouterPush);
    routerI18nEvents.on('back', onRouterBack);
    return () => {
      routerI18nEvents.off('push', onRouterPush);
      routerI18nEvents.off('back', onRouterBack);
    };
  }, [router]);
}
