'use client';

import { useAudioUnlocked } from '@ocodelib/mui/hooks';
import { LectureViewDialogOpenEventHandler } from '@repo-ui/events/lecture-open';
import { LightboxOpenEventHandler } from '@repo-ui/events/lightbox-open';
import { UnlockAudioEvent } from '@repo-ui/events/unlock-audio';
import { useRouterEvent } from '@repo-ui/hooks/useRouterEvent';
import { useRouterI18nEvent } from '@repo-ui/hooks/useRouterI18nEvent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function CommonEventHandlers() {
  useRouterEvent();
  useRouterI18nEvent();
  useAudioUnlocked({
    onUnlocked: () => {
      UnlockAudioEvent.emit(true);
    },
  });

  return (
    <>
      <LightboxOpenEventHandler />
      <LectureViewDialogOpenEventHandler />
      {/* 느려서 제거 */}
      {/* <MonacoThemePickerOpenEventHandler /> */}
      <ToastContainer
        position="top-center"
        hideProgressBar
        autoClose={2500}
        pauseOnHover
        draggable
      />
    </>
  );
}
