import { createSvgIcon } from '@mui/material';

const HomeTopMenuGalleryIcon = createSvgIcon(
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8141 4.71133C24.3208 4.25388 23.6728 3.99979 23 4H7L6.76533 4.00667C5.74681 4.06652 4.78966 4.51332 4.08971 5.25565C3.38977 5.99798 2.99994 6.97972 3 8V21.3333L3.00667 21.5333C3.05712 22.2042 3.35929 22.8312 3.8526 23.2887C4.34591 23.7461 4.9939 24.0002 5.66667 24V8L5.676 7.844C5.71421 7.51967 5.87012 7.22063 6.11417 7.00362C6.35822 6.78661 6.67342 6.66671 7 6.66667H25.6667L25.66 6.46667C25.6095 5.79579 25.3074 5.16878 24.8141 4.71133ZM28.8856 8.78105C28.3855 8.28095 27.7072 8 27 8H9.66667C8.95942 8 8.28115 8.28095 7.78105 8.78105C7.28095 9.28115 7 9.95942 7 10.6667V25.7773C7 26.4846 7.28095 27.1629 7.78105 27.663C8.28115 28.163 8.95942 28.444 9.66667 28.444H27C27.7072 28.444 28.3855 28.163 28.8856 27.663C29.3857 27.1629 29.6667 26.4846 29.6667 25.7773V10.6667C29.6667 9.95942 29.3857 9.28115 28.8856 8.78105ZM23.7475 13.2525C23.3761 12.881 22.8586 12.6667 22.3333 12.6667C21.8029 12.6667 21.2942 12.8774 20.9191 13.2525C20.544 13.6275 20.3333 14.1362 20.3333 14.6667C20.3333 15.1971 20.544 15.7058 20.9191 16.0809C21.2942 16.456 21.8029 16.6667 22.3333 16.6667C22.8586 16.6667 23.3761 16.4523 23.7475 16.0809C24.119 15.7094 24.3333 15.192 24.3333 14.6667C24.3333 14.1414 24.119 13.6239 23.7475 13.2525ZM16.9037 15.8417C16.6537 15.5916 16.3145 15.4512 15.9609 15.4512C15.6073 15.4512 15.2681 15.5917 15.0181 15.8418L9.66911 21.1914C9.14845 21.7121 9.1485 22.5563 9.66923 23.077C10.19 23.5976 11.0342 23.5976 11.5548 23.0769L15.961 18.6702L21.4564 24.1656C21.9771 24.6863 22.8214 24.6863 23.3421 24.1656C23.8535 23.6542 23.8626 22.8306 23.3693 22.3081L23.8036 21.8738L25.1803 23.2505C25.701 23.7712 26.5452 23.7712 27.0659 23.2505C27.5866 22.7298 27.5866 21.8856 27.0659 21.3649L24.7464 19.0454C24.4964 18.7953 24.1572 18.6548 23.8036 18.6548C23.45 18.6548 23.1109 18.7953 22.8608 19.0454L21.4841 20.4221L16.9037 15.8417Z"
      fill="#039BE5"
    />
  </svg>,
  'HomeTopMenuGalleryIcon',
);

export default HomeTopMenuGalleryIcon;
