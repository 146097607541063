import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import type { ContainerProps } from '@mui/material';
import { Box, Breadcrumbs, Container, IconButton, Typography } from '@mui/material';
import { LinkI18n } from '../i18n/routing';

interface LinkPart {
  href?: string;
  title: string;
}

export interface CustomBreadcrumbsProps {
  section?: string;
  linkParts?: LinkPart[];
  currentTitle: string;
  disableContainer?: boolean;
  rootUrl?: string;
  grayText?: boolean;
}

export function CustomBreadcrumbs(props: CustomBreadcrumbsProps) {
  const {
    rootUrl = '/',
    disableContainer = false,
    section,
    linkParts = [],
    currentTitle,
    grayText = false,
  } = props;

  return (
    <Box
      className="CustomBreadcrumbs-root"
      sx={{
        py: 0,
        px: 1,
        '& .MuiTypography-colorInherit': {
          color: '#fff',
        },

        '& .MuiTypography-colorPrimary': {
          color: '#242424',
        },
      }}
    >
      <CanDisableContainer disableGutters disabled={disableContainer}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{
            pt: 1,
            '& .MuiBreadcrumbs-separator': {
              color: grayText ? '#777' : '#fff',
            },
          }}
        >
          <IconButton
            href={rootUrl}
            color="default"
            size="large"
            sx={{ p: 0.5, '& svg': { color: grayText ? '#777' : '#fff' } }}
          >
            <HomeOutlinedIcon sx={{ width: 20 }} />
          </IconButton>
          {section && (
            <Typography
              sx={{ fontWeight: 300, fontSize: '0.9rem', color: grayText ? '#777' : '#fff' }}
            >
              {section}
            </Typography>
          )}

          {linkParts
            .map((it, i) => ({ ...it, key: i }))
            .map(({ href, title, key }) =>
              href ? (
                <LinkI18n href={href} key={key}>
                  {title}
                </LinkI18n>
              ) : (
                <Typography key={key}>{title}</Typography>
              ),
            )}

          <Typography style={{ fontSize: '0.9rem', color: grayText ? '#777' : '#fff' }}>
            {currentTitle}
          </Typography>
        </Breadcrumbs>
      </CanDisableContainer>
    </Box>
  );
}

type CanDisableContainerProps = {
  disabled?: boolean;
} & ContainerProps;

function CanDisableContainer(props: CanDisableContainerProps) {
  const { sx, disabled = false, className, style, maxWidth, children, ...restProps } = props;

  return (
    <Container
      className={className}
      style={style}
      disableGutters
      sx={sx}
      {...restProps}
      maxWidth={disabled ? false : maxWidth}
    >
      {children}
    </Container>
  );
}
