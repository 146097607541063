import { createSvgIcon } from '@mui/material';

const HomeTtsIcon = createSvgIcon(
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.75">
      <path
        d="M21 4.5V7.5C21 7.89782 20.842 8.27936 20.5607 8.56066C20.2794 8.84196 19.8978 9 19.5 9C19.1022 9 18.7206 8.84196 18.4393 8.56066C18.158 8.27936 18 7.89782 18 7.5V6H13.5V18H15C15.3978 18 15.7794 18.158 16.0607 18.4393C16.342 18.7206 16.5 19.1022 16.5 19.5C16.5 19.8978 16.342 20.2794 16.0607 20.5607C15.7794 20.842 15.3978 21 15 21H9C8.60218 21 8.22064 20.842 7.93934 20.5607C7.65804 20.2794 7.5 19.8978 7.5 19.5C7.5 19.1022 7.65804 18.7206 7.93934 18.4393C8.22064 18.158 8.60218 18 9 18H10.5V6H6V7.5C6 7.89782 5.84196 8.27936 5.56066 8.56066C5.27936 8.84196 4.89782 9 4.5 9C4.10218 9 3.72064 8.84196 3.43934 8.56066C3.15804 8.27936 3 7.89782 3 7.5V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H19.5C19.8978 3 20.2794 3.15804 20.5607 3.43934C20.842 3.72064 21 4.10218 21 4.5Z"
        fill="#039BE5"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5364 47.5035C31.4179 47.3331 31.3143 47.1782 31.2261 47.0429H27.9999C27.3413 47.0429 26.7097 46.7813 26.244 46.3156C25.7782 45.8499 25.5166 45.2182 25.5166 44.5596V35.3396C25.5166 34.681 25.7782 34.0493 26.244 33.5836C26.7097 33.1179 27.3413 32.8563 27.9999 32.8563H31.2317C31.3198 32.7234 31.4233 32.5712 31.5415 32.404C31.9632 31.8073 32.5794 31.0079 33.3557 30.2041C34.8651 28.6411 37.184 26.8496 39.9999 26.8496C40.6351 26.8496 41.1499 27.3645 41.1499 27.9996V51.9996C41.1499 52.6347 40.6351 53.1496 39.9999 53.1496C37.1765 53.1496 34.8558 51.3244 33.3491 49.7383C32.5734 48.9216 31.9577 48.1096 31.5364 47.5035ZM44.4767 33.2327C44.9003 32.7594 45.6273 32.7191 46.1005 33.1427C46.5769 33.569 47.0073 34.0441 47.3846 34.5602L47.3856 34.5616C48.5369 36.1412 49.1551 38.0465 49.1503 40.0011C49.15 42.0175 48.5064 43.8868 47.4139 45.3996L47.4133 45.4004C47.0291 45.9315 46.5889 46.4197 46.1003 46.8568C45.6269 47.2802 44.8999 47.2397 44.4765 46.7663C44.053 46.2929 44.0935 45.5659 44.5669 45.1425C44.9326 44.8154 45.262 44.45 45.5496 44.0525C46.366 42.9218 46.8503 41.5212 46.8503 39.9996V39.9966C46.8541 38.5307 46.3907 37.1017 45.5274 35.917C45.2451 35.5309 44.9231 35.1755 44.5667 34.8565C44.0934 34.433 44.0531 33.7059 44.4767 33.2327ZM47.6096 27.6741C47.0695 27.34 46.3608 27.507 46.0267 28.0471C45.6925 28.5873 45.8596 29.296 46.3997 29.6301C49.8731 31.7787 52.1833 35.619 52.1833 39.9988C52.1833 44.4475 49.7999 48.3409 46.2347 50.4686C45.6893 50.7941 45.511 51.5001 45.8365 52.0454C46.162 52.5908 46.8679 52.7691 47.4133 52.4436C51.6454 49.918 54.4833 45.2901 54.4833 39.9988C54.4833 34.7892 51.7322 30.2242 47.6096 27.6741Z"
      fill="#C862DA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6848 20.182L38.1783 16.6754C37.7877 16.2849 37.7877 15.6517 38.1783 15.2612C38.5688 14.8707 39.202 14.8707 39.5925 15.2612L41.9626 17.6313C42.6733 14.3318 41.2004 10.8677 38.1812 9.08067C36.9307 8.34192 35.5026 7.95738 34.0502 7.96849C32.5978 7.97959 31.1758 8.38586 29.9367 9.14371C29.4656 9.43187 28.85 9.28354 28.5618 8.81239C28.2737 8.34124 28.422 7.7257 28.8932 7.43753C30.442 6.49024 32.2194 5.98243 34.0349 5.96855C35.8504 5.95466 37.636 6.4356 39.1991 7.35909C42.747 9.45856 44.5963 13.4337 44.0431 17.3522L46.1341 15.2612C46.5246 14.8707 47.1578 14.8707 47.5483 15.2612C47.9388 15.6517 47.9388 16.2849 47.5483 16.6754L43.5704 20.6533C43.1799 21.0438 42.5467 21.0438 42.1562 20.6533L41.7247 20.2218C41.711 20.2089 41.6977 20.1956 41.6848 20.182Z"
      fill="url(#paint0_linear_2178_29765)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2178_29765"
        x1="28.915"
        y1="-3.08594"
        x2="40.6301"
        y2="19.8373"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#039BE5" />
        <stop offset="1" stopColor="#C862DA" />
      </linearGradient>
    </defs>
  </svg>,
  'HomeTtsIcon',
);

export default HomeTtsIcon;
