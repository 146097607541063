'use client';

import { useIsomorphicEffect } from '@ocodelib/mui/hooks';
import { useRouter } from 'next/navigation';
import { customRouterEvents } from '../router/index';

export function useRouterEvent() {
  const router = useRouter();

  useIsomorphicEffect(() => {
    const onRouterPush = (link: string) => {
      router.push(link);
    };

    const onRouterBack = () => {
      router.back();
    };

    customRouterEvents.on('push', onRouterPush);
    customRouterEvents.on('back', onRouterBack);
    return () => {
      customRouterEvents.off('push', onRouterPush);
      customRouterEvents.off('back', onRouterBack);
    };
  }, [router]);
}
