import type { lib } from 'crypto-js';
import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

export class AESEncoder {
  private key: lib.WordArray;
  private iv: lib.WordArray;

  constructor(secret: string) {
    this.key = encUtf8.parse(secret);
    this.iv = encUtf8.parse(secret.substring(0, 16));
  }

  enc = (plaintext: string): string => {
    const encrypted = AES.encrypt(plaintext, this.key, {
      iv: this.iv,
    });
    return encrypted.toString().toString();
  };

  dec = (encoded: string): string => {
    return AES.decrypt(encoded, this.key, { iv: this.iv }).toString(encUtf8);
  };
}
