import type { SxProps } from '@mui/material';
import { Box, ButtonBase, Typography } from '@mui/material';
import { flatSx } from '@ocodelib/mui';
import { LinkI18n } from '@repo-ui/i18n/routing';
import clsx from 'clsx';

interface Props {
  sx?: SxProps;
  className?: string;
  imgUrl: string;
  bgColor: string;
  textColor?: string;
  title: string;
  desc: string;
  href: string;
}

export default function MenuLongCard(props: Props) {
  const { sx, className, imgUrl, bgColor, title, textColor, desc, href } = props;
  return (
    <ButtonBase
      className={clsx('MenuLongCard-root', className)}
      LinkComponent={LinkI18n}
      href={href}
      sx={flatSx(
        {
          position: 'relative',
          width: '100%',
          height: { xs: 200, md: 450 },
          background: bgColor,
          borderRadius: '15px',
          py: 3,
          px: 4,
          whiteSpace: 'pre-line',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
        sx,
      )}
    >
      {/* img */}
      <Box
        className="MenuLongCard-imgBox"
        sx={{
          position: 'absolute',
          bottom: { xs: 0, md: 30 },
          right: { xs: 15, md: 30 },
          width: { xs: 90, md: 100, lg: 154 },
          height: { md: 312, lg: 442 },
          '& img': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={imgUrl} alt="" />
      </Box>
      {/* text */}
      <Box className="MenuLongCard-textBox" sx={{ alignSelf: 'flex-start' }}>
        <Typography
          sx={{
            position: 'relative',
            fontSize: { xs: '1.7rem', md: '1.8rem' },
            color: textColor,
            fontWeight: '500',
            whiteSpace: 'pre-line',
          }}
        >
          {title}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'pre-line',
            color: textColor,
            fontWeight: 300,
            fontSize: '0.95rem',
          }}
        >
          {desc}
        </Typography>
      </Box>
    </ButtonBase>
  );
}
