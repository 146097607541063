import type { SxProps } from '@mui/material';
import { mainLayoutConstants as LAYOUT } from '../../main-layout-constants';

export const rootSx: SxProps = {
  position: 'relative',
  color: LAYOUT.sidemenu.fgColor,
  overflowX: 'hidden',

  '&::-webkit-scrollbar': {
    width: 10,
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0)',
    borderRadius: '10px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.3)',
    // outline: '2px solid slategrey',
    borderRadius: '10px',
    border: '2px solid rgba(0,0,0,.1)',
  },

  '& > .MuiDivider-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    my: 1,
  },
  height: '100%',

  '::before': {
    position: 'absolute',
    content: "''",
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    background: LAYOUT.sidemenu.bgColor,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // filter: 'blur(0.5px)',
  },

  '& .Sidebar-logoWrapper': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    pr: 0,
    '& .Sidebar-logoLink': {
      flex: 1,
      lineHeight: 1,
      p: 0,
      textDecoration: 'none',
      '& .Sidebar-logoImage': {
        objectFit: 'contain',
        width: 110,
        height: '100%',
      },
    },
  },
  '& .Sidebar-drawerHeader': {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    px: 1,
    borderRadius: 1,
    zIndex: 1,
    fontSize: '1.1rem',
    textDecoration: 'none',
  },
  '& .Sidebar-drawerHeaderText': {
    flexGrow: 1,
    color: '#fff',
    fontSize: '1.1rem',
    fontWeight: 700,
    textDecoration: 'none',
  },
  '& .Sidebar-drawerHeaderChevronIcon': {
    color: LAYOUT.sidemenu.fgColor,
  },
  '& .Sidebar-label': {
    p: 0,
    mb: 0,
    ml: 1,
    position: 'relative',
    '& .MuiListItemText-root .MuiTypography-root': {
      // color: SIDEMENU_FG_COLOR,
      color: '#FFFF00',
      fontSize: '0.75rem',
      display: 'inline-block',
      pl: 1,
      pt: '2px',
      pb: 0,
      border: '1px solid rgba(255,255,255,0.0)',
      borderRadius: '4px',
    },
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '0px',
      width: '16px',
      height: '0px',
      border: '0px dashed rgba(255,255,255,0.5)',
      zIndex: 1,
      content: '""',
    },
  },

  '& .SideMenuItem-icon, & .SideSectionMenu-icon': {
    minWidth: 32,
    mr: 1,
  },

  '& .SideSectionMenu-title': {
    '& + .SectionMenu-root': {
      mt: '1px',
    },
  },
};
