'use client';

import LanguageIcon from '@mui/icons-material/Language';
import type { SxProps } from '@mui/material';
import { ButtonBase, ListItemText, Menu, MenuItem } from '@mui/material';
import { useLocale } from 'next-intl';
import React, { useState } from 'react';
import { redirectI18n, usePathnameI18n } from '../i18n/routing';
import clsx from 'clsx';

const localeMessages = {
  ko: {
    ko: '한국어',
    en: '영어',
  },
  en: {
    ko: 'Korean',
    en: 'English',
  },
};

interface Props {
  sx?: SxProps;
  className?: string;
}

export function LocaleSwitcher(props: Props) {
  const { sx, className } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(menuAnchorEl);
  const pathname = usePathnameI18n();
  const currentLocale = useLocale();

  const messages = (localeMessages[currentLocale as any] || localeMessages.en) as Record<
    string,
    string
  >;

  // 언어 선택 버튼 클릭
  const handleClickSelectBtn = (event: React.MouseEvent) => {
    setMenuAnchorEl(event.target as HTMLElement);
  };

  // 언어 선택 메뉴 닫기
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // 언어 선택 메뉴에서 언어를 선택한 경우
  const handleChangeLocale = (locale: string) => () => {
    // router.push 또는 redirect를 사용할 수 있는데, history가 조금 다르다.
    // router.push는 언어 변경의 history가 유지된다.

    // router.push({ pathname }, { locale });
    redirectI18n({ href: pathname, locale });
  };

  return (
    <>
      <ButtonBase
        onClick={handleClickSelectBtn}
        className={clsx('LocaleSwitcher-root', className)}
        sx={sx}
      >
        <LanguageIcon />
      </ButtonBase>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpened}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'localeswitch-button',
        }}
        sx={{
          '& .MuiMenuItem-root': {
            minWidth: 120,
            justifyContent: 'center',
          },
        }}
      >
        {Object.entries(messages).map(([locale, nm]) => (
          <MenuItem
            onClick={handleChangeLocale(locale)}
            selected={locale === currentLocale}
            key={locale}
          >
            <ListItemText>{nm}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
