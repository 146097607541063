import type { SxProps } from '@mui/material';
import { ButtonBase, Typography } from '@mui/material';
import { Center, flatSx, StackColumn } from '@ocodelib/mui';
import { sxFlexColumn, sxFlexRow } from '@repo-foundation/util/sx-util';
import { LinkI18n } from '@repo-ui/i18n/routing';
import clsx from 'clsx';
import NextImage from 'next/image';

interface Props {
  className?: string;
  sx?: SxProps;
  horizontal?: boolean;
  icon: string;
  iconSize: { w: number; h: number };
  title: string;
  desc: string;
  textColor: string;
  bgColor: string;
  imgBgColor: string;
  href: string;
}

export default function CodingCard(props: Props) {
  const { horizontal = false, ...restProps } = props;
  return (
    <>
      {horizontal ? <CodingCardHorizontal {...restProps} /> : <CodingCardVertical {...restProps} />}
    </>
  );
}

// 가로 방향의 카드
function CodingCardHorizontal(props: Props) {
  const { sx, className, icon, iconSize, title, desc, textColor, bgColor, imgBgColor, href } =
    props;

  return (
    <ButtonBase
      className={clsx('CodingCardHorizontal-root', className)}
      LinkComponent={LinkI18n}
      href={href}
      sx={flatSx(
        {
          ...sxFlexRow({ align: 'stretch', justify: 'flex-start' }),
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 2,
          background: bgColor,
        },
        sx,
      )}
    >
      <Center
        sx={{
          background: imgBgColor,
          width: '20vw',
          minWidth: 140,
          maxWidth: 200,
          height: 160,
          '& img': {
            width: '13vw',
            height: 'auto',
            minWidth: 60,
            maxWidth: 100,
          },
        }}
      >
        <NextImage src={icon} alt="" width={iconSize.w} height={iconSize.h} priority={false} />
      </Center>

      <StackColumn alignItems="flex-start" justifyContent="center" sx={{ ml: 3 }}>
        <Typography
          variant="subtitle1"
          sx={{
            color: textColor,
            overflow: 'hidden',
            fontSize: '1.5rem',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            color: '#fff',
            mt: 1,
            overflow: 'hidden',
            fontSize: '0.8rem',
            fontWeight: 300,
            whiteSpace: 'pre-line',
          }}
        >
          {desc}
        </Typography>
      </StackColumn>
    </ButtonBase>
  );
}

// 세로 방향의 카드
function CodingCardVertical(props: Props) {
  const { sx, className, icon, iconSize, title, desc, textColor, bgColor, imgBgColor, href } =
    props;

  return (
    <ButtonBase
      className={clsx('CodingCardVertical-root', className)}
      LinkComponent={LinkI18n}
      href={href}
      sx={flatSx(
        {
          ...sxFlexColumn({ align: 'stretch' }),
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          borderRadius: 2,
          background: bgColor,
          pb: 5,
        },
        sx,
      )}
    >
      <Center
        sx={{
          background: imgBgColor,
          height: 140,
        }}
      >
        <NextImage src={icon} alt="" width={iconSize.w} height={iconSize.h} priority={false} />
      </Center>

      <Typography
        variant="subtitle1"
        sx={{
          mt: 2,
          color: textColor,
          textAlign: 'center',
          overflow: 'hidden',
          fontSize: '1.5rem',
          fontWeight: 500,
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-line',
        }}
      >
        {title}
      </Typography>

      <Typography
        sx={{
          color: '#fff',
          mt: 1,
          overflow: 'hidden',
          textAlign: 'center',
          fontSize: '0.8rem',
          fontWeight: 300,
          whiteSpace: 'pre-line',
        }}
      >
        {desc}
      </Typography>
    </ButtonBase>
  );
}
